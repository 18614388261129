.my-table { background: rgba(35, 33, 91, 0.15); border-radius: 22px 22px 0px 0px; padding: 20px; display: flex; justify-content: space-between; font-family: 'Quicksand', sans-serif;}
.my-table h3 { margin: 0px; }
.my-table .search-label { display: flex; position: relative; }
.my-table .search-label input { padding-right: 3rem; background: #FFFFFF; border: 1px solid rgba(33, 37, 41, 0.8); border-radius: 5px 15px; min-width: 314px; max-height: 40px; position: relative; }
.my-table { background: rgba(35, 33, 91, 0.15); border-radius: 22px 22px 0px 0px; padding: 20px; display: flex; justify-content: space-between; }
.my-table h3 { margin: 0px; }
.my-table .search-label { display: flex; overflow: hidden; border: 1px solid rgba(33, 37, 41, 0.8); border-radius: 5px 15px; min-width: 314px; max-height: 40px; position: relative; }
.my-table .search-label input { background: #FFFFFF !important; border: none; height: 100%; }
.my-table strong { font-weight: 700; font-size: 22px; line-height: 28px; color: #212529; }
.my-table button { position: absolute; top: 0px; right: 0px; font-size: 0px; width: 40px; height: 38px; border: none; background: #F1F1F1; border-radius: 0px 15px 5px 0px; padding: 0px; margin: 0px !important; }
.my-table-wraper table { width: 100%; margin: 0px; }
.my-table-wraper table td { padding: 20px; font-family: 'Quicksand', sans-serif; font-weight: 500; font-size: 0.875rem; line-height: 1.43; letter-spacing: 0.01071em; vertical-align: middle;}
.my-table-wraper table td:not(:first-child) { text-align: center; }
.my-table-wraper table th:not(:first-child) { text-align: center; }
.my-table-wraper table tbody tr:last-child td:first-child { overflow: hidden; }
.my-table-wraper table tbody tr:last-child td { color: #23454e; border: 1px solid rgba(35, 33, 91, 0.1) !important; border-bottom: 0px !important; font-family: 'Quicksand', sans-serif; font-weight: 500;}
.my-table-wraper .table-bordered tbody tr:last-child{border-bottom-width: 0px;}
.my-table-wraper table tbody tr td:first-child { border-left: 0px !important; }
.my-table-wraper table tbody tr td:last-child { border-right: 0px !important; }
.my-table-wraper { border-radius: 22px; border: 1px solid rgba(35, 33, 91, 0.1); overflow: hidden; }
.table-responsive.common-table table thead th { padding: 16px; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #23215B; background: rgba(35, 33, 91, 0.05); font-family: 'Quicksand', sans-serif; border-width: 0px;}
.my-table-wraper table tbody tr td .assign-detail{
    color: #FFF;
    padding: 0.7rem 0.5rem;
    text-align: center;
    border-radius: 6px;
    background-color: #0092b8;
    cursor: pointer;
    transition: all 300ms linear;
}
.my-table-wraper table tbody tr td .assign-detail:hover {
    background-color: #006681;
}
.my-table-wraper .table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: transparent;
}
.my-table-wraper .common-table table tbody tr td .disable-link {
    color: rgba(0, 0, 0, 0.60);
    background-color: #f0f1f2;
    pointer-events: none;
}
.my-table-wraper table tbody tr td.react-bs-table-no-data {
    text-align: center;
}