header{background: #FFFFFF; box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.1);}
.navbar-brand{ padding: 0px;}
.header-top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;   
}
.header-top-main{
    padding: 10px 0px;
    border-bottom: 1px solid rgba(33, 37, 41, 0.2);   width: 100%;
}
nav.navbar.navbar-expand-lg.navbar-light { flex-wrap: wrap; padding: 0px;}
ul.header-social-media { display: flex; flex-wrap: wrap; display: none;  }
ul.header-social-media li img { max-width: 40px; }
ul.header-social-media li { margin: 0px 10px; }
ul.header-social-media { margin: 0px -10px; }
.logedin img { width: 40px; height: 40px; border-radius: 100%; overflow: hidden; object-fit: cover; object-position: center;background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);}
.header-top form { position: relative; margin-left: 15px; }
.header-top form button[type="submit"] { position: absolute; top: 0px; right: 0px; font-size: 0px; width: 40px; height: 38px; border: none;background: #F1F1F1;
    border-radius: 0px 15px 5px 0px; padding: 0px;  margin: 0px !important; }
.header-top form button[type="submit"]:hover{ background-color: #0092B8; }
.logedin { margin-left: auto; }
.logedin span { margin-right: 10px; font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0092B8;
}
header .header-search input {
    height: auto;
    background-color: #fff !important;
    border-radius: 0px;
}
.header-top > form {
    margin-left: auto;
}
.header-button { display: flex; align-items: center; width: 100%; min-height: 107px; padding: 15px 0px}
.header-button ul.header-buttons {  display: flex; align-items: center; margin: 0px -15px; margin-left: auto;}
.header-button ul.header-buttons li { margin: 0px 15px;   text-align: center;   }
.header-button ul.header-buttons li a { color: #212529; font-style: normal;padding: 5px 10px;width: 121px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px; display: block;}
.header-button ul.header-buttons li a:hover,
.header-top ul.header-top-buttons a:hover { color: #FFFFFF;}
ul.navbar-nav li a.nav-link,
ul.navbar-nav li .nav-link-btn {
    padding: 0;
    border: none;
    background-color: transparent;
}
ul.navbar-nav li a.nav-link,
ul.navbar-nav li .nav-link-btn {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #212529;
}
ul.navbar-nav li a.nav-link:hover,
ul.navbar-nav li .nav-link-btn:hover{ color: #0092B8; background-color: transparent; border: none;}
ul.navbar-nav li {
    margin: 0px 25px;
}

ul.navbar-nav li a.active.nav-link,
ul.navbar-nav li .active.nav-link-btn {
    color: #0092B8;
}
.header-top form {
    max-width: 314px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(33, 37, 41, 0.8);
    border-radius: 5px 15px;
    overflow: hidden;
    width: 100%;
}
.header-top form input {
    border: none;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    height: auto;
    padding-right: 3rem;
}
.navbar-expand-lg .navbar-nav .nav-link{ padding: 0px;}
.menu-wraper {
    width: 100%;
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
}



/* after login header */
ul.header-top-buttons {
    display: flex;
    flex-wrap: wrap;
}
ul.header-top-buttons a {
    color: #212529;
    font-style: normal;
    padding: 5px 10px;
    width: 121px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    display: block;
    text-align: center;
    margin: 0px 10px;
    background-size: 100% 100% !important;
}
ul.header-top-buttons a:hover{ color:#0092B8}
ul.header-top-buttons a.yellow-button:hover{ color: #fff; }
ul.header-top-buttons li.change-button a {
    width: 163px;
    background-size: 100% 100%;
}
ul.header-top-buttons a.yellow-button {
    height: auto;
}







@media(max-width: 1440px){
    ul.navbar-nav li {
        margin: 0px 15px;
    }
    ul.navbar-nav li a.nav-link,
    .logedin span {
        font-size: 20px;
    }
}



@media(max-width: 1200px){   
    ul.navbar-nav li {
        margin: 0px 10px;
    }
    ul.navbar-nav li a.nav-link,
    ul.navbar-nav li .nav-link-btn { font-size: 18px; }
    .header-button {
        padding: 10px 0px;
        min-height: auto;
    }
    .header-button ul.header-buttons li a{ font-size: 18px;}

    button.navbar-toggler {
        margin-left: auto;
    }
}


@media(max-width: 991px){   
    .menu-wraper {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0px;
        top: 97%;
        padding: 15px;
        border-radius: 0px 0px 20px 20px;
        border-top: 1px solid #000;
        z-index: 999;
    }    
    .header-button {
        position: relative;
    }
    button.navbar-toggler {
        position: absolute;
        right: 0px;
    }
    button.navbar-toggler {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
    .navbar-toggler:focus{ box-shadow: none;;}
    ul.navbar-nav li {
        margin: 5px 0px;
    }    
    .header-button ul.header-buttons {
        margin: 10px -15px 0px -15px;
        justify-content: center;
        width: 100%; 
    }
    .header-button ul.header-buttons{ margin: 10px -5px 0px -5px}
    .header-button ul.header-buttons li {
        margin: 0px 5px;
    }
    .logedin {
        width: 100%;
    } 
}



@media( max-width : 767px){
    .header-top-main ul.header-top-buttons {
        margin-top: 1rem;
    }
    /* ul.header-top-buttons li:first-child {
        width: 100%;
        margin-bottom: 10px;
    }
    ul.header-top-buttons li:first-child a {
        margin: 0 auto;
    } */
    ul.header-top-buttons {
        justify-content: center;
    }
    ul.header-top-buttons {
        width: 100%;
    }
    ul.header-top-buttons li.change-button a {
        font-size: 16px;
        max-width: max-content;
    }
    ul.header-top-buttons a{
        font-size: 16px;
        max-width: max-content;
    }
    .header-top form {
        margin: 0 auto;
    }
    .header-button .navbar-brand img {
        width: 108px;
    }
}