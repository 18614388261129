/* .pre-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  z-index: 10001;
  background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
}
.pre-loader .loader-effect {
  top: 50%;
  left: 50%;
  -khtml-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  position: absolute;
}
.pre-loader #animationWindow {
  position: relative;
} */

.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }