section.counter-section {
    position: relative;
    padding: 160px 0px 80px;
    background: #1C1A49; 
    margin-top: 70px;
    overflow: hidden;
}

/* section.counter-section:before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 67px;
    background-image: url('../../../assets/images/wave-shape.svg');
    z-index: 9;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: top;
    animation: wave 20s -3s linear infinite;
} */
section.counter-section:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0px;
    width: 100%;
    height: 67px;
    background: url(../../../assets/images/curve.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
}


ul.counter-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px -15px;
    position: relative;
}

ul.counter-wraper li {
    width: calc(25% - 30px);
    margin: 0px 15px;
    text-align: center;
}

ul.counter-wraper li img {
    object-fit: contain;
    display: block;
    margin: 0 auto;
}

ul.counter-wraper li .rounded-shape {
    background: url(../../../assets/images//round-shape.png) no-repeat center / contain; width: 123px; height: 120px; margin: 0px auto 20px auto; display: flex; align-items: center;  justify-content: center;
}

ul.counter-wraper strong {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 8px;
}

ul.counter-wraper span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
}

.counter-wraper:before{
    content: '';
    position: absolute;
    top: -80px;
    left: 83px; background: url(../../../assets/images/shape-12.png)no-repeat center / contain; width: 85px; background-size: contain !important; background-repeat: no-repeat !important;
    height: 69px;
    animation: dot_shape 12s ease-out infinite;
}

.counter-wraper:after{
    content: '';
    position: absolute;
    top: -55px;
    right: 77px;
    background: url(../../../assets/images/shape-22.png)no-repeat center / contain;  background-size: contain !important; background-repeat: no-repeat !important;
    width: 63px; 
    height: 64px;
    animation: square_shape 12s ease-out infinite;
}

/*------------- 17.12.22 [START] -------------*/
@keyframes dot_shape{0%{transform:translate(0,0)}20%{transform:translate(-30px,40px)}40%{transform:translate(60px,60px)}60%{transform:translate(70px,40px)}80%{transform:translate(40px,-70px)}100%{transform:translate(0,0)}}
@keyframes square_shape{0%{transform:translateX(0)}25%{transform:translateY(-40px)}50%{transform:translateY(0)}75%{transform:translateY(40px)}100%{transform:translateY(0)}}
/*------------- 17.12.22 [END] -------------*/


@media (max-width: 991px){
    ul.counter-wraper li {
        width: calc(33.3% - 30px);
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .counter-wraper:before,
    .counter-wraper:after {
        content: none;
    }
}

@media(max-width : 767px){
    ul.counter-wraper li {
        width: calc(100% - 30px);
        margin: 15px;
    }
    .counter-wraper:before,
    .counter-wraper:after {
        content: none;
    }
    section.counter-section {
        padding: 100px 0px 40px;
    }
    section.counter-section{
        margin-top: 40px;
    }
    ul.counter-wraper strong {
        font-size: 30px;
        line-height: 40px;
    }
    ul.counter-wraper span {
        font-size: 20px;
    }
    section.testimonial {
        padding: 40px 0px;
    }
}