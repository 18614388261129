/* footer */

footer {
  padding-top: 70px;
  position: relative;
}
footer:before {
  content: "";
  position: absolute;
  top: 300px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #F4F4F4;
  z-index: -1;
}
footer * {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #23454e;
}
footer a {
  line-height: 30px;
}
.footer-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  padding-top: 70px;
}
.footer-left {
  width: calc(35% - 30px);
  margin: 0px 15px;
}
.footer-left p {
  color: #23454e;
}
.footer-right {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  /* -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2; */
}
.footer-subscribe {
  width: calc(30% - 30px);
  margin: 0px 15px;
}
.footer-subscribe h4 {
  color: #38bc83;
  margin-top: 16px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;  
  display: block;  
  text-transform: capitalize;
  font-family: 'Quicksand', sans-serif;
}
.footer-subscribe .btn {
  color: #212529;
  font-weight: 600;
  font-size: 18px;
  border-color: #CED4DA;
  border-left: 0;
  background: #FAAF11;
}
.footer-subscribe .btn:hover {
  color: #FFF;
  background: #0092B8;
}

.footer-right ul {
  width: calc(50% - 30px);
  page-break-inside: avoid;
  break-inside: avoid;
  margin: 0px 15px 15px;
  display: table-row-group;
}

.footer-right ul li > strong {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #167287;
  margin-bottom: 30px;
  display: block;
  margin-top: 16px;
}
.footer-left > img {
  margin-bottom: 10px;
}
footer ul.header-social-media {
  justify-content: center;
  padding: 20px 0px;
  display: flex;
  margin: 0px;
}

.footer-social-media {
  border-top: 1px solid rgba(33, 37, 41, 0.2);
  margin-top: 15px;
}
footer .footer-right a,
footer .footer-right .standards-link span {
  position: relative;
  padding-left: 20px;
  color: #677788;
}
footer .footer-right a:hover {
  color: #0092b8;
}
footer span {
  color: #677788;
}
footer .footer-right .standards-link span {
  display: block;
}
/* footer .footer-right .standards-link span a,
footer .footer-right .standards-link a {
  padding: 0px;
} */
footer .footer-right .standards-link a {
  padding: 0px;
}
footer .footer-right .standards-link .link-group {
  color: #677788;
  padding-left: 20px;
  word-break: break-word;
}
footer .footer-right a:before,
footer .footer-right .standards-link span::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0px;
  width: 8px;
  height: 8px;
  border: 1px solid #e74c25;
  transform: rotate(45deg);
  border-left: 0px;
  border-bottom: 0px;
}
footer .footer-right .standards-link a:before {
  content: none;
}
.footer-sign-up {
  min-height: 540px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 89px;
}
.footer-sign-up h3 {
  margin-bottom: 30px;
}
.footer-sign-up p {
  font-style: normal;
  max-width: 600px;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #23454e;
}
footer .footer-right a.active {
  color: #0092b8;
}

footer.after-login:before {
  display: none;
}

footer.after-login .footer-wrap {
  padding-top: 0px;
}
footer.after-login {
  background: #F4F4F4;
  margin-top: 80px;
}
.footer-subscribe form .form-text { width: 100%; min-height: 32px; font-size: .655em; line-height: normal; left: 0; bottom: -35px; position: absolute; display: block; }

@media (max-width: 1440px) {
  .footer-sign-up {
    min-height: 498px;
  }
  .footer-sign-up h3 {
    font-size: 30px;
  }
  .footer-sign-up p {
    font-size: 23px;
  }
  .footer-right ul li,
  .footer-left p {
    font-size: 16px;
  }
  .footer-right ul li > strong,
  .footer-subscribe h4 {
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  /* .footer-right ul {
    width: calc(50% - 30px);
  } */
  footer.after-login {
    margin-top: 60px;
    padding-top: 60px;
}
}

@media (max-width: 1190px) {
  .footer-sign-up {
    justify-content: center;
    padding-top: 100px;
  }
  /* footer:before {
    display: none;
} */
  /* footer {
    padding-top: 0px;
    position: relative;
  } */

  .footer-sign-up h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .footer-sign-up p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 991px) {
  .footer-sign-up {
    min-height: auto;
    padding-bottom: 0px;
    min-height: 320px;
    justify-content: center;
    padding-top: 60px;
  }
  .footer-sign-up h3 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .footer-sign-up p {
    font-size: 18px;
    max-width: 400px;
    line-height: 24px;
  }
  .footer-right {
    width: 60%;
    justify-content: flex-start;
    /* -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; */
  }
}

@media (max-width: 767px) {
  .footer-left,
  .footer-right {
    width: 100%;
    margin: 0px;
  }
  .footer-subscribe {
    width: 70%;
    margin: 0px;
  }
  .footer-wrap {
    margin: 0px;
  }

  ul.counter-wraper strong {
    font-size: 30px;
    line-height: 40px;
  }

  ul.counter-wraper span {
    font-size: 20px;
  }

  .footer-sign-up h3 {
    font-size: 12px;
    max-width: 148px;
    line-height: 15px;
    margin-bottom: 10px;
  }

  .footer-sign-up p {
    display: none;
  }

  .footer-sign-up .yellow-button {
    font-size: 12px;
    padding: 0px 16px;
  }

  .footer-sign-up {
    height: 160px;
    justify-content: center;
    padding-top: 26px;
    min-height: auto;
  }
  .footer-right ul {
    width: calc(50% - 30px);
    margin: 0px;
  }
  footer span {
    width: 100%;
  }
  footer ul.header-social-media {
    width: 100%;
  }
  .footer-right ul li > strong,
  .footer-subscribe h4 {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .footer-wrap {
    padding-top: 40px;
  }

  footer:before {
    display: none;
  }
  footer {
    background: #f7f7f7;
  }
  footer {
    padding-top: 40px;
  }
  footer.after-login {
    margin-top: 40px;
  }
}

@media (max-width: 400px) {
  .footer-sign-up {
    padding-top: 16px;
  }
  .footer-right ul {
    width: calc(100% - 30px);
  }
  .footer-right {
    justify-content: flex-start;
    /* -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; */
  }
  .footer-subscribe {
    width: 100%;
    margin: 0px;
  }
}
