section.latest-blog {
    background: #F7F7F7;
    padding: 100px 0px ;
    position: relative;
}
section.latest-blog::before{ content:''; position: absolute; top: 0px; left: 0px; width: 100%; height: 67px; background: url(../../../assets/images/curve.png);}
.avtar-name span {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #23454E;
}
.latest-blog-wrap li {
    width: calc(33.3% - 20px);
    margin: 0px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 30px 20px;
}
.latest-blog-wrap ul {
    margin: 0px -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
}
.latest-blog-wrap li .blog-image {
    text-align: center;
    margin-bottom: 17px;
    margin-top: -150px;
    padding: 0px;
    box-shadow: none;
    background: transparent;
    height: 275px;
}
.data-comments {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}
.blog-date {
    padding-right: 25px;
}
.data-comments span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0A303A;
    margin-left: 8px;
}
.data-comments img {
    max-width: 23px;
}
.blog-post-details .data-comments {
    justify-content: left;
}
.latest-blog-wrap p {
    font-style: normal;
    font-weight: 500;
    color: #212529;
}
a.yellow-button.blur-color {
    background: #0092B8;
    color: #fff;
}
.latest-blog-wrap li {
    margin-top: 150px;
}
.blog-main .latest-blog-wrap > ul > li:nth-child(4n+1), .blog-main .latest-blog-wrap > ul > li:nth-child(4n+4) { width: calc(60% - 20px); }
.blog-main .blog-btn {
    width: 100%;
    text-align: center;
    position: relative;
}
.blog-main .blog-btn .yellow-button {
    line-height: 38px;
    display: inline-block;
}



@media(max-width:992px) {
    .blog-main .latest-blog-wrap > ul > li { width: calc(50% - 20px); }
    .blog-main .latest-blog-wrap > ul > li:nth-child(4n+1), .blog-main .latest-blog-wrap > ul > li:nth-child(4n+4) {width: calc(50% - 20px); }
}
@media(max-width:767px) {
    .blog-main .latest-blog-wrap > ul > li:nth-child(4n+1), .blog-main .latest-blog-wrap > ul > li:nth-child(4n+4) { width: calc(50% - 20px);  }
}


@media(max-width: 1440px){
    .data-comments img {
        max-width: 20px;
    }
    .data-comments span {
        font-size: 16px;
        margin-left: 5px;
    }
    .latest-blog-wrap li {
        padding: 15px;
    }
}


@media(max-width: 1190px){
    .data-comments img {
        max-width: 20px;
    }
    .data-comments span {
        font-size: 14px;
        margin-left: 10px;
    }
    .latest-blog-wrap li {
        padding: 15px 20px;
    }
    section.latest-blog { 
        padding: 70px 0px; 
    }
}

@media(max-width: 991px){
    .latest-blog-wrap li {
        width: calc(50% - 20px);
        margin-top: 181px;        
    }
    .blog-commnets  .blog-commnet-image {
        width: 60px;
        height: 60px;
    }
    .blog-commnets .blogReply .blog-commnet-image{
        width: 60px;
        height: 60px;
    }
    .blog-commnets .blogReply {
        padding-left: 70px;
    }
    .blog-post-comment {
        width: calc(100% - 75px);
        margin-left: 15px;
    }
}

@media (max-width: 767px){
    .latest-blog-wrap li {
        width: calc(100% - 20px);
        margin-top: 150px;
        margin-bottom: 1rem;
    }
    .latest-blog-wrap li:last-child {
        margin-bottom: 0px;
    }
    .blog-main .latest-blog-wrap > ul > li {
        width: calc(100% - 20px);
    }
    .blog-main .latest-blog-wrap > ul > li:nth-child(4n+1), .blog-main .latest-blog-wrap > ul > li:nth-child(4n+4) {
        width: calc(100% - 20px);
    }

    .latest-blog-wrap li .blog-image {
        height: 200px;
    }
    .blog-commnets .blogReply {
        padding-left: 30px;
    }
    .blog-post-comment{ 
        margin-left:0px; 
    }
}