@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap');

/* font-family: 'Catamaran', sans-serif;
font-family: 'Quicksand', sans-serif; */
* { box-sizing: border-box; margin: 0; padding: 0; }
body { margin: 0; padding: 0; font-family: 'Quicksand', sans-serif; font-style: normal; color: #545555; font-size: 20px; font-weight: 500; line-height: 32px; letter-spacing: 0; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased;}
a { color: #17293e; text-decoration: none; transition: all 300ms linear; -moz-transition: all 300ms linear; -o-transition: all 300ms linear; -ms-transition: all 300ms linear; -webkit-transition: all 300ms linear; }
a:focus { outline: none; text-decoration: none; }
a:hover, a:active { outline: 0; text-decoration: none; color: #0092B8; }
p { margin: 0 0 20px; word-break: break-word; }
ol, ul { margin: 0; padding: 0; }
ul li { list-style: none; padding: 0px; margin: 0px; }
table { border-collapse: collapse; border-spacing: 0; }
hr { background-color: rgba(0, 0, 0, 0.1); border: 0; height: 1px; margin-bottom: 23px; }
h1, h2, h3, h4, h5, h6 { clear: both; font-weight: 700; word-break: break-word; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 { font-style: normal; font-weight: 700; font-size: 52px; line-height: 65px; color: #FFFFFF; }
h2 { font-style: normal; font-weight: 600; font-size: 42px; line-height: 52px; color: #23215B; }
body h3 { font-style: normal; font-weight: 600; font-size: 38px; line-height: 48px; color: #212529; }
h4 { font-style: normal; font-family: 'Catamaran'; font-weight: 500; font-size: 32px; line-height: 52px; color: #212529; }
h5 { font-weight: 600; font-size: 22px; line-height: 28px; text-align: center; color: #212529; margin: 0px; }
h6 { color: var(--bs-gray-dark); font-size: 20px; line-height: 30px; }
img { max-width: 100%; border: 0; }
select { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input, button, textarea, select { border: 1px solid #ccc; outline: none; font-style: normal; font-size: 20px; color: #000; font-weight: 400; line-height: 35px; font-weight: 300; }
input[type=submit], button { cursor: pointer; transition: all 0.4s ease-in-out; }
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset; }
::-webkit-selection { background-color: #47d5ff; color: #fff; text-shadow: none; }
::-moz-selection { background-color: #47d5ff; color: #fff; text-shadow: none; }
::selection { background-color: #47d5ff; color: #fff; text-shadow: none; }
.container { max-width: 1354px; float: none; padding: 0px 15px; }
[type=radio]:checked, [type=radio]:not(:checked) { position: absolute; left: -9999px; }
[type=radio]:checked + span, [type=radio]:not(:checked) + span { position: relative; padding-left: 28px; cursor: pointer; line-height: 20px; display: inline-block; color: #666; }
[type=radio]:checked + span:before, [type=radio]:not(:checked) + span:before { content: ""; position: absolute; left: 0; top: 0; width: 18px; height: 18px; border-radius: 4px; background-color: #d8d8d8; border-radius: 0%; }
[type=radio]:checked + span:after, [type=radio]:not(:checked) + span:after { content: "\f00c"; width: 12px; height: 13px; color: #1a80c3; font-family: fontawesome; top: -1px; left: 4px; font-size: 12px; border-radius: 100%; transition: all 0.2s ease; position: absolute; }
[type=radio]:not(:checked) + span:after { opacity: 0; transform: scale(0); }
[type=radio]:checked + span:after { opacity: 1; transform: scale(1); }
.btn:disabled {
    background-color: #6c757d;
    border: none;
}
button.slick-prev.slick-arrow { position: absolute; top: 50%; z-index: 9; transform: translateY(-50%); padding: 0; background-color: white; font-size: 0; border: none; left: 40px; }
button.slick-prev.slick-arrow:before { content: ""; width: 35px; height: 35px; border-left: 1px solid #9f9f9f; border-bottom: 1px solid #9f9f9f; position: absolute; padding: 10px; transform: rotate(270deg); }
button.slick-next.slick-arrow { position: absolute; top: 50%; z-index: 99; transform: translateY(-50%); padding: 0; background-color: white; font-size: 0; border: none; right: 40px; }
button.slick-next.slick-arrow:before { content: ""; width: 35px; height: 35px; border-right: 1px solid #9f9f9f; border-top: 1px solid #9f9f9f; position: absolute; right: 0px; }
button.slick-prev.slick-arrow:focus { outline: none; }
button.slick-next.slick-arrow:focus { outline: none; }
ul.slick-dots { display: flex; align-items: center; margin: 0 auto; justify-content: center; margin-top: 30px; bottom: 0px; position: static; line-height: 0px; }
ul.slick-dots li { margin: 0px 6px; }
ul.slick-dots li button { font-size: 0px; width: 28px; height: 6px; background: #99D3E3; border-radius: 3px; padding: 0px; }
ul.slick-dots li.slick-active button { width: 50px; background: #0092B8; }
.slick-dots li { width: auto; height: auto }
.slick-dots li button:before { display: none; }
.slick-dots li button:after { display: none; }
:-ms-input-placeholder { font-family: 'Quicksand'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 20px; color: rgba(108, 117, 125, 0.8) !important; }
::-moz-placeholder { font-family: 'Quicksand'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 20px; color: rgba(108, 117, 125, 0.8) !important; }
::placeholder { font-family: 'Quicksand'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 20px; color: rgba(108, 117, 125, 0.8) !important; }
:root { --cursor-size: 20px; --bg-color: #f2f5f8; --dot-color: #545555; --prime-color: #6975eb; --block-color: #000; --dot-size: 2px; --dot-space: 30px; }
.yellow-button { background: #FAAF11; border-radius: 30px; height: 58px; display: flex; align-items: center; justify-content: center; font-style: normal; font-weight: 700; font-size: 24px; line-height: 30px; color: #212529; border: none; padding: 10px 40px; font-family: 'Quicksand', sans-serif;}
.yellow-button:hover { background: #0092B8; color: #fff; }
.footer-right ul li > strong.blue { color: #167287; }
.footer-right ul li > strong.purple { color: #6572AE; background-color: transparent; }
.footer-right ul li > strong.green { color: #38BC83; }
.footer-right ul li > strong.light-blue { color: #11A5E4; }
.pink { background-color: #FE4B7B; }
.purple { background-color: #B250FE; }
.yellow { background-color: #FAB319; }
.pink-color { color: #FE4B7B; }
.dark-blur-color { color: #23215B }
.green-color { color: #38BC83; }
.purple-color { color: #B250FE; }
.btn:first-child:hover, :not(.btn-check)+.btn:hover { color: var(--bs-btn-hover-color); background-color: #0092B8; border-color: #0092B8; }
.main-title h2 {text-transform: capitalize;}
.custom-accordion-data-picker-wrap .MuiInputBase-formControl {
    font-family: 'Quicksand', sans-serif;
}
.MuiFormControl-root .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
    border-color: #CED4DA;
}
.MuiFormControl-root .MuiInputBase-formControl.Mui-focused .MuiOutlinedInput-notchedOutline {    
    border-width: 1px;
    border-color: #0092B8;
}
input[type=file]::file-selector-button {
    /* color: #fff;
    border: none;
    background-color: #0092b8; */
    height: 56px;
}
/* input[type=file]::file-selector-button:hover {
    color: #212529;
    background-color: #FAAF11;
} */

/* home */
.hero-banner-wrap {
    width: 100%;
    height: 865px;
    position: relative;
}
.hero-banner-wrap img { width: 100%; height: 100%; object-fit: cover; object-position: center center; }
ul.hero-menu { display: flex; flex-wrap: wrap; margin: 0px -15px; justify-content: center; margin-top: -82px; z-index: 9; position: relative; }
ul.hero-menu li { background: #FFFFFF; box-shadow: 0px 4px 15px 3px rgb(0 0 0 / 10%); border-radius: 10px; width: calc(14.28% - 30px); margin: 0px 15px; display: flex; align-items: center; justify-content: center; height: 165px; }
section.common-section { padding: 70px 0px 0px 0px; }
.main-title { text-align: center; margin-bottom: 30px; }

/* common section */
.common-section-wrap { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin: 0px -15px; position: relative; }
.common-section { position: relative; }
.common-section:before, 
.blog-main::before,
.blog-details-main::before { content: ''; position: absolute; top: 91px; left: 0px; background: url(./assets/images/about-shape-1.png); width: 75px; height: 100px; background-size: contain !important; background-repeat: no-repeat !important; animation: flowerhome infinite alternate-reverse ease-in 12s; }
.common-section:after, 
.blog-main::after,
.blog-details-main::after { content: ''; position: absolute; top: 100px; right: 48px; background: url(./assets/images/about-shape-2.png); width: 75px; height: 90px; background-size: contain !important; background-repeat: no-repeat !important; animation: stars infinite alternate-reverse ease-in 12s; }
.common-section-content { width: calc(50% - 30px); margin: 0px 15px; }
.common-section-image { width: calc(50% - 30px); margin: 0px 15px; }
.common-section-content h4 { margin-bottom: 15px; }
.common-section-content span { font-style: normal; font-size: 27px; line-height: 48px; color: #23454E; display: block; margin-bottom: 40px; }
.student-common-section > div.right-image { flex-direction: row-reverse; }
.common-section-wrap.image-left { display: flex; flex-direction: row-reverse; }
section.creating_class_rosters { padding-top: 70px; position: relative; margin-bottom: 70px; }
section.creating_class_rosters:before,
.blog-details-wrap:before,
.blog-main .latest-blog-wrap::before { content: ''; position: absolute; top: 0px; left: 83px; background: url(./assets/images/shape-tow.png)no-repeat center / contain; width: 133px; height: 68px; background-size: contain !important; background-repeat: no-repeat !important; animation: circles infinite alternate-reverse ease-in 8s; }
section.creating_class_rosters:after,
.blog-details-wrap:after,
.blog-main .latest-blog-wrap::after { content: ''; position: absolute; top: 30px; right: 77px; background: url(./assets/images/shape-one.png)no-repeat center / contain; width: 105px; height: 89px; background-size: contain !important; background-repeat: no-repeat !important; animation: circle infinite alternate-reverse ease-in 8s; }
.student-project { background: #F7F7F7; padding: 40px 0px 0px; }
.creating_class_rosters_wrap span { font-style: normal; font-weight: 500; font-size: 32px; line-height: 48px; text-align: center; color: #212529; max-width: 731px; display: block; margin: 0 auto 40px auto; }
.about-main-page .common-section-content h2 { display: none; }

/* about page css */
section.common-banner:before { content: ''; position: absolute; left: 0px; left: 0px; right: 0px; bottom: 0px; background: rgba(35, 33, 91, 0.85); z-index: 9; top: 0px; }
section.common-banner { position: relative; background-size: cover !important; background-position: center center !important; background-repeat: no-repeat !important; }
.common-banner-wraper { display: flex; align-items: center; justify-content: center; min-height: 512px; flex-direction: column; position: relative; z-index: 9; }
.common-banner-wraper h1 {text-transform: capitalize;}
.common-banner-wraper h3 { color: #fff; }
.bread-cum { display: flex; flex-wrap: wrap; }
.bread-cum li { list-style: none; }
section.common-banner:before { content: ''; position: absolute; left: 0px; left: 0px; right: 0px; bottom: 0px; background: rgba(35, 33, 91, 0.85); z-index: 9; top: 0px; }
section.common-banner { position: relative;    /* min-height: 512px; */ background-size: cover !important; background-position: center center !important; background-repeat: no-repeat !important; overflow: hidden;}
.bread-cum { display: flex; flex-wrap: wrap; }
.bread-cum li { list-style: none; }
ul.bread-cum li { font-style: normal; font-weight: 700; font-size: 28px; line-height: 35px; color: rgba(255, 255, 255, 0.7); margin: 0px 20px; text-transform: capitalize; position: relative; }
ul.bread-cum li a { color: #FAAF11; }
ul.bread-cum li:before { content: ''; position: absolute; right: -20px; width: 15px; height: 15px; border: 4px solid #faaa2c; transform: rotate(45deg) translateY(-50%); top: 50%; border-left: 0px; border-bottom: 0px; }
ul.bread-cum li:last-child:before { display: none; }
span.common-header-animation:before { content: ''; position: absolute; top: 45px; left: 45px; width: 70px; height: 75px; background: url(../src//assets//images/flower.png)no-repeat center / contain; z-index: 9; background-size: contain !important; background-repeat: no-repeat !important; animation: Flower infinite alternate-reverse ease-in 10s; }
span.common-header-animation:after { content: ''; position: absolute; bottom: 79px; left: 99px; width: 99px; height: 80px; background: url(../src//assets//images/star.png)no-repeat center / contain; z-index: 9; background-size: contain !important; background-repeat: no-repeat !important; animation: star  infinite alternate-reverse ease-in 10s; }
span.common-header-animation-two:before { content: ''; position: absolute; top: 35px; right: 40px; width: 104px; height: 82px; background: url(../src//assets//images/line1.png)no-repeat center / contain; z-index: 9; background-size: contain !important; background-repeat: no-repeat !important; animation: line infinite alternate-reverse ease-in 10s; }
span.common-header-animation-two:after { content: ''; position: absolute; bottom: 100px; right: 69px; width: 85px; height: 75px; background: url(../src//assets//images/car.png)no-repeat center / contain; z-index: 9; background-size: contain !important; background-repeat: no-repeat !important; animation: car  infinite alternate-reverse ease-in 10s; }
section.common-banner:after { content: ''; position: absolute; bottom: 0px; left: 0px; width: 200%; height: 67px; background: url(../src/assets//images/curv2.png); z-index: 9; background-size: contain; background-repeat: repeat-x; background-position: bottom; bottom: -1px; animation: wave 20s -3s linear infinite;}
.about-page-wraper { padding: 40px 0px 0px; }
.about-main-page .student-project { background: #fff; position: relative; }
.about-main-page .student-project:first-child:before {
    content: '';
    position: absolute;
    top: 100px;
    left: 100px;
    background: url('../src/assets/images/shape2.png')no-repeat center / contain;
    width: 30px;
    height: 30px;
    text-align: center;
    animation: rotation 8s infinite linear;
    transform: translateZ(0);
}
.about-main-page .student-project:first-child::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    background: url('../src/assets/images/shape1.png')no-repeat center / contain;
    width: 30px;
    height: 30px;
    text-align: center;
    animation: rotation 5s infinite linear;
    transform: translateZ(0);
}
.about-main-page .student-project:nth-child(2)::after {
    content: '';
    position: absolute;
    top: 100px;
    right: 60px;
    background: url('../src/assets/images/circle-shape2.png')no-repeat center / contain;
    width: 30px;
    height: 30px;
    text-align: center;
    animation: mover 1s infinite alternate;
    transform: translateZ(0);
}
.about-common-image .common-section-image { height: 405px; filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1)); background: #FFFFFF; border-radius: 30px; overflow: hidden; }
.common-section-content strong { margin-bottom: 20px; display: block; }
.about-main-page .student-project:nth-child(3):before {
    content: '';
    position: absolute;
    top: 100px;
    left: 100px;
    background: url('../src/assets/images/sun.png')no-repeat center / contain;
    width: 60px;
    height: 60px;
    text-align: center;
    animation: rotation 8s infinite linear;
    transform: translateZ(0);
}
.about-main-page .student-project:nth-child(4):before {
    content: '';
    position: absolute;
    top: 100px;
    right: 60px;
    background: url('../src/assets/images/sun2.png')no-repeat center / contain;
    width: 60px;
    height: 60px;
    text-align: center;
    animation: rotation 8s infinite linear;
    transform: translateZ(0);
}

/* form-css */
.common-pop .modal-dialog { width: 1324px; max-width: 100%; border-radius: 20px 20px 0px 0px; overflow: hidden; }
.common-pop .modal-header { background: #0092B8; padding: 14px 40px; color: #fff; }
.common-pop .modal-title.h4 { color: #fff; font-style: normal; font-weight: 600; font-size: 32px; line-height: 40px; }
.common-pop .btn-close { color: #fff !important; filter: invert(1000%) brightness(10000%) grayscale(0); opacity: 1; }
.common-pop .modal-dialog { width: 1324px; max-width: 100%; border-radius: 20px 20px 0px 0px; overflow: hidden; }
.common-pop .modal-header { background: #0092B8; padding: 14px 40px; color: #fff; }
.common-pop .modal-title.h4 { color: #fff; font-style: normal; font-weight: 600; font-size: 32px; line-height: 40px; }
.common-pop .btn-close { color: #fff !important; filter: invert(1000%) brightness(10000%) grayscale(0); opacity: 1; }
.form-control:disabled { opacity: 1; background: #E6F4F8; border: 1px solid #CED4DA; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #0092B8; font-family: 'Quicksand';}
.form-select:disabled { opacity: 1; background-color: #E6F4F8; border: 1px solid #CED4DA; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #0092B8; font-family: 'Quicksand';}
.sign-in-form-wraper { display: flex; flex-wrap: wrap; }
.sign-in-form-wraper .common-form .checkbox {margin-top: 20px;}
.sign-in-image img { width: 100%; height: 100%; object-fit: cover; }
.common-form { padding-left: 50px; }
.common-pop .modal-body { padding: 40px; }
.sign-in-image img { background: #FFFFFF; box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); border-radius: 20px; }
form .from-group { width: 100%; }
form .from-group > * { width: 100%; }
.common-form { width: calc(100% - 575px); }
.sign-in-image { width: 575px; }
.form-group.half-fuied-main, .form-group.third-fuied-main { margin-left: -24px; display: flex; flex-wrap: wrap; margin-right: -24px; }
.form-group.half-fuied-main > div { margin: 0px 24px; width: calc(50% - 48px); }
.form-group.third-fuied-main > div { margin: 0px 24px; width: calc(33.33% - 48px); }
.full-fuied { margin-bottom: 25px; }
.form-group.half-fuied-main > div.reset-pass { width: 100%; text-align: right; margin-top: 20px; }
.form-group.half-fuied-main > div.reset-pass a { font-style: normal; font-weight: 500; font-size: 16px; line-height: 20px; color: #0092B8; display: inline-block; }
select.form-select, input.form-control { background: #FFFFFF; border: 1px solid #CED4DA; border-radius: 15px; height: 56px; }
select.form-select:focus, input.form-control:focus, textarea.form-control:focus { box-shadow: none; border-color: #0092B8; }
select.form-select { background: url(./assets/images/arrow-select.png)no-repeat 96%; }
.form-floating > textarea.form-control { height: 180px; resize: none; border-radius: 10px; }
.form-floating label { position: relative; height: auto; padding: 0px; margin: 0px; }
.field-reversed textarea + label { display: none; }
.solve { display: flex;    /* align-items: center; */ }
.solve span { padding: 20px; display: flex; align-items: center; justify-content: center; height: 55px; background: #FFFFFF;    /* border: 1px solid #CED4DA; */ border-radius: 15px; margin: 0px 15px; font-weight: 900; font-size: 25px; line-height: 23px; color: #212529; }
.form-floating>.form-control:not(:placeholder-shown) { padding: 0.375rem 0.75rem; }
.custom-form-width { width: 55px; text-align: center; font-weight: 500; font-size: 24px; line-height: 30px; color: #212529; }
.custom-form-width { width: 55px; text-align: center; font-weight: 500; font-size: 24px; line-height: 30px; color: #212529; }
.blog-form button.yellow-button { margin-top: 50px; }
form .yellow-button { width: auto; padding: 10px 35px; }
form .yellow-button { width: auto; padding: 10px 35px; }
form > p { margin-top: 40px; margin-bottom: 5px; font-style: normal; font-weight: 500; font-size: 20px; line-height: 28px;/* identical to box height, or 140% */ color: #212529; }
form > em { font-weight: 700; font-size: 16px; line-height: 28px;/* identical to box height, or 175% */ color: #23454E; }
form > p a { color: #0092B8; }
.sign-up-form-wraper .sign-in-form-wraper { padding: 30px; border-radius: 20px; box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); }
.sign-up-form-wraper .form-group.half-fuied-main > div { margin-bottom: 25px; }
.sign-up-form-wraper { padding: 70px 0px 0px 0px; position: relative }
label.form-label { font-style: normal; font-weight: 500; font-size: 18px; line-height: 25px; color: #212529; }
.sign-up-form-wraper span.error { font-style: italic; font-weight: 700; font-size: 24px; line-height: 28px; color: #DC3545; margin-top: 40px; display: block; }
p.chech-box-form { margin: 0px  0px 50px 0px; }
.common-form h2 { margin-bottom: 30px; }
.chech-box-form { font-weight: 500; font-size: 16px; line-height: 28px; padding-left: 40px; }
.chech-box-form a { color: #0092B8 }
.common-form h2 { margin-bottom: 30px; }
p.chech-box-form { font-weight: 500; font-size: 16px; line-height: 28px; padding-left: 40px; }
.form-group.half-fuied-main > label { margin: 0px 25px 10px; }
textarea.form-control { height: 110px; border-radius: 15px; resize: none;}
header .header-search  input { height: auto; background-color: #fff; border-radius: 0px; }
.account-details .form-group a.question-mark img { width: 16px; object-fit: contain; }
.account-details > form > p { font-style: normal; font-weight: 500; font-size: 18px; line-height: 25px; color: #212529; }
label.form-label { font-style: normal; font-weight: 500; font-size: 16px; line-height: 25px; color: #212529; font-family: 'Quicksand';}
.account-details > form > p a { margin-left: 10px; }
.account-details > form > p a img { max-width: 18px; }
.switch { position: relative; }
.switch svg { display: none; }
.switch > label { width: 140px; height: 44px; background: #F8F9FA; border: 1px solid #CED4DA; border-radius: 15px; position: relative; }
.switch span { font-size: 0px; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; border-radius: 14px; }
.switch svg + span:before { content: "On"; width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; background: #198754; color: #fff; z-index: 9; font-weight: 500; font-size: 22px; line-height: 28px; }
.switch svg + span:after { content: "Off"; width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; background: transparent; color: rgba(33, 37, 41, 0.5); position: absolute; right: 0px; top: 0px; z-index: 9; font-weight: 500; font-size: 22px; line-height: 28px; background: transparent; color: rgba(33, 37, 41, 0.5); }
.switch input:checked + svg + span:before { background: transparent; color: rgba(33, 37, 41, 0.5); }
.switch input:checked + svg + span:after { background: #198754; color: #fff; }
.switch { display: flex; align-items: end; padding: 0px; }
.css-1aquho2-MuiTabs-indicator { background-color: #0092B8; }
.email-setting { border-bottom: 1px solid rgba(33, 37, 41, 0.1); display: flex; flex-wrap: wrap; width: 100%; margin-bottom: 20px; }
.email-setting .email-setting-content { width: 80%; }
.email-setting .swith { width: 20%; }
.email-setting .switch { width: 20%; }
.email-setting .switch label { margin: 0px; }
.email-setting-content strong { font-weight: 500; font-size: 20px; line-height: 25px; color: #212529; margin-bottom: 10px; display: block; font-family: 'Quicksand', sans-serif; }
.email-setting-content p {font-family: 'Quicksand', sans-serif;}
/* .blog-detail { max-height: 300px; overflow: hidden; text-overflow: ellipsis; overflow: hidden !important; max-height: 300px; display: -webkit-box !important; -webkit-line-clamp: 6; -webkit-box-orient: vertical; overflow: hidden; margin-bottom: 5px; flex: inherit !important; margin-bottom: 20px; } */
.blog-detail {
    max-width: 100%;
    height: 200px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
a.delete { background: red; padding: 5px 20px; font-size: 16px; color: #fff; border-radius: 20px; }
.sign-in-form-wraper .common-form .input-group-text,
.modal .modal-body .input-group-text {
    position: absolute;
    top: 50%;
    right: 15px;
    padding: 0;
    border: none;
    background-color: transparent;
}
.sign-in-form-wraper .common-form .input-password .form-control,
.modal .modal-body .input-password .form-control {
    padding-right: 3rem;
}

/*checkbox*/

/* .checkbox > input { display: none; }
.checkbox label:before { content: ''; position: absolute; top: 7px; left: 0px; width: 20px; height: 20px; background: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.25); border-radius: 4px; }
.checkbox label { position: relative; }
.checkbox label:after { content: ''; position: absolute; top: 13px; left: 4px; width: 12px; border: 1px solid #0092c7; height: 5px; transform: rotate(134deg); border-left: 0px; border-bottom: 0px; opacity: 0px; visibility: hidden; }
.checkbox input:checked + label:after { opacity: 1; visibility: visible; }
.checkbox label a { color: #0092B8; }
 */

.custom-check { position: absolute; top: 0px; left: 0px; }
.checkbox { position: relative; }
small.text-muted.form-text { color: #DC3545 !IMPORTANT; }
a.yellow-button:hover { background: #0092B8; color: #fff; }
a.page-link { background: #0092B8; border-radius: 6px; }
body ul.pagination li button { border: none; background: #E9ECEF; border-radius: 6px; color: #212529; width: 40px; height: 40px; padding: 0px; display: flex; align-items: center; justify-content: center; font-style: normal; font-weight: 600; font-size: 24px; line-height: 30px; }
body ul.pagination li button:hover, ul.pagination li button.active { background: #0092B8 !important; color: #fff !important; cursor: pointer; }
body ul.pagination .btn.disabled, body ul.pagination .btn:disabled { background: #E9ECEF; }
body ul.pagination .btn:disabled svg path { fill: #212529 }
body ul.pagination li button.enable { opacity: 0.6; }
body ul.pagination li button.enable:hover { background: #e9ecef; }
body ul.pagination li button.enable svg path { fill: #212529; }
body ul.pagination li button:hover svg path { fill: #fff; }
ul.pagination { margin-top: 50px; }
ul.pagination li a.disable:hover { background: #e9ecef; }
.page-item:first-child .page-link { background: #0092B8; color: #fff; }
ul.pagination li { margin: 0px 10px; }
.active>.page-link, .page-link.active { background: #faaf11; border-color: #faaf11; color: #000; }
.blog-main { padding: 70px 0px; position: relative;}
.blog-image { background: #FFFFFF; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); border-radius: 20px; padding: 20px; }
.blog-image img { width: 100%; height: 100%; object-fit: cover; border-radius: 20px; overflow: hidden; max-height: 600px; overflow: hidden; border-radius: 20px; }
.blog-details-main { padding: 70px 0px 70px; position: relative; }
.blog-main .latest-blog-wrap:before { bottom: 0; top: auto;}
.blog-main .latest-blog-wrap:after { bottom: 0; top: auto;}
.blog-main .blog-image { margin-bottom: 15px; }
.blog-post-details { padding: 40px 0px 0px; }
.blog-post-details strong { font-style: normal; font-weight: 500; font-size: 32px; line-height: 32px;/* or 100% */ color: #212529; margin-bottom: 20px; display: block; }
.blog-post-details p { font-weight: 500; font-size: 18px; line-height: 32px;/* or 178% */ color: #23454E; }
.blog-post-details { padding: 40px 0px 0px; }
.blog-post-details strong { font-style: normal; font-weight: 500; font-size: 32px; line-height: 32px;/* or 100% */ color: #212529; margin-bottom: 20px; display: block; }
.blog-post-details p { font-weight: 500; font-size: 18px; line-height: 32px;/* or 178% */ color: #23454E; margin: 0px; }
.blog-commnets ul li { display: flex; flex-wrap: wrap; }
.blog-commnets ul li .blog-commnet-image { width: 100px; height: 100px; border-radius: 100%; overflow: hidden; }
.blog-post-comment { width: calc(100% - 130px); margin-left: 30px; }
.blog-post-comment strong { font-style: normal; font-weight: 500; font-size: 24px; line-height: 32px; margin: 0px; }
.blog-post-comment span { font-style: normal; font-weight: 400; font-size: 16px; line-height: 32px; color: #212529; }
.blog-post-comment a { font-weight: 500; font-size: 20px; line-height: 32px; }
.blog-commnets { padding-top: 40px; }
.blog-commnets h4 { margin-bottom: 20px; }
.blog-post-details { padding: 40px 0px 0px; }
.blog-post-details strong { font-style: normal; font-weight: 500; font-size: 32px; line-height: 32px;/* or 100% */ color: #212529; margin-bottom: 20px; display: block; }
.blog-post-details p { font-weight: 500; font-size: 18px; line-height: 32px;/* or 178% */ color: #23454E; margin: 0px; }
.blog-commnets .blog-msg { display: flex; flex-wrap: wrap; max-width: 992px; margin-bottom: 30px; }
.blog-commnets .blog-msg .blog-commnet-image { width: 100px; height: 100px; border-radius: 100%; overflow: hidden; }
.blog-commnets .blog-msg .blog-commnet-image img { width: 100%; height: 100%; object-fit: cover; }
.blog-commnets .blogReply { display: flex; flex-wrap: wrap; max-width: 992px; margin-bottom: 30px; }
.blog-commnets .blogReply .blog-commnet-image { width: 100px; height: 100px; border-radius: 100%; overflow: hidden; }
.blog-commnets .blogReply .blog-commnet-image img { width: 100%; height: 100%; object-fit: cover; }
.blog-commnets .blogReply { padding-left: 130px; }
.blog-post-comment { width: calc(100% - 130px); margin-left: 30px; }
.blog-post-comment strong { color: #23215B; font-style: normal; font-weight: 600; font-size: 24px; line-height: 32px; margin-bottom: 10px; }
.blog-post-comment .date-info { width: 100%; margin-bottom: 5px;}
.blog-post-comment span { font-style: normal; font-weight: 400; font-size: 16px; line-height: 32px; color: #000; }
.blog-post-comment a { font-weight: 500; font-size: 20px; line-height: 32px; }
.blog-commnets { padding-top: 40px; }
.blog-commnets h4 { margin-bottom: 20px; }
.blog-commnets ul li p { margin-bottom: 10px; }
.blog-details-main {position: relative;}
.blog-details-main .blog-details-wrap .blog-image {margin-bottom: 3rem;}
.blog-details-main .blog-details-wrap:before { bottom: 0; top: auto;}
.blog-details-main .blog-details-wrap:after { bottom: 0; top: auto;}

/* faq */
.faq-main-section { padding-top: 70px; position: relative; }
.faq-main-section:before { content: ''; position: absolute; top: 80px; right: 29px; background: url(../src/assets//images/light.png)no-repeat; animation: light 1s infinite alternate; transform: translateZ(0); width: 161px; height: 91px; }
.faq-main-section:after { content: ''; position: absolute; top: 90px; left: 48px; background: url(../src/assets/images/star1.png)no-repeat center / contain; animation: rotation 5s infinite linear; transform: translateZ(0); width: 31px; height: 31px; }
.stars:before { content: ''; position: absolute; top: 227px; left: 80px; background: url(../src/assets/images/star2.png)no-repeat center / contain; animation: rotation 5s infinite linear; transform: translateZ(0); width: 31px; height: 31px; }
.stars:after { content: ''; position: absolute; top: 357px; left: 30px; background: url(../src/assets/images/star3.png)no-repeat center / contain; animation: rotation 5s infinite linear; transform: translateZ(0); width: 31px; height: 31px; }
.common-accordion-repeat > div { box-shadow: none; border-radius: 0px !important; border-bottom: 1px solid rgba(33, 37, 41, 0.2); }
.common-accordion-repeat > div > div { padding: 0px; }
.MuiAccordionSummary-content p { padding-right: 1rem; font-weight: 600; font-size: 24px; line-height: 30px; color: #212529; font-family: 'Quicksand'; }
.Mui-expanded .MuiAccordionSummary-content p { color: #0092B8; }
.Mui-expanded .MuiAccordionSummary-root:before { content: '+'; position: absolute; right: 0px; top: 50%; transform: translateY(-50%); font-size: 50px; content: "-"; color: #0092b8; }
.MuiCollapse-vertical p { font-style: normal; font-weight: 500; font-size: 20px; line-height: 25px; color: #23454E; text-align: left; font-family: 'Quicksand';}
.MuiAccordionSummary-content { margin: 26px 0px 10px; padding-right: 30px; }
.MuiAccordionDetails-root { padding: 0px 0px 20px 0px; }
.MuiAccordionSummary-root { position: relative; }
.MuiAccordionSummary-root:before { content: '+'; position: absolute; right: 0px; top: 22px; font-size: 32px; }
.address-here > strong { font-weight: 600; font-size: 20px; line-height: 25px; margin-bottom: 20px; display: block; }
.address-here span { display: block; }
.address-here span a:hover { color: #0092B8; }
.address-here strong { font-weight: 600 }
.terms-details { padding-top: 70px; }

/* introduction */
.intro-header { position: relative; text-align: center; background: rgba(35, 33, 91, 0.05); border-radius: 22px 22px 0px 0px; }
.intro-header { padding: 14px; }
.table-of-content { padding-top: 70px; }
.roman-numeral-counter li { position: relative; border: 1px solid rgba(35, 33, 91, 0.1); padding: 12px 15px 12px 117px; border-top: 0px; }
.roman-numeral-counter li > span { font-weight: 600; font-size: 20px; line-height: 25px; color: #212529; }
.roman-numeral-counter li:before { font-weight: 600; font-size: 20px; line-height: 25px; color: #212529; }
.roman-numeral-counter { counter-reset: roman-numeral-counter; border-radius: 20px; }
.roman-numeral-counter li { counter-increment: roman-numeral-counter 1; }
.roman-numeral-counter li:before { content: counter(roman-numeral-counter, lower-roman) ". "; margin-right: 20px; min-width: 40px; display: inline-block; padding: 13px 0px; border-right: 1px solid rgba(35, 33, 91, 0.1); position: absolute; left: 60px; top: 0px; height: 100%; }
.roman-numeral-counter li:last-child { border-radius: 0px 0px 20px 20px; }
ul.roman-numeral-counter { margin-bottom: 40px; }
.inner-counter li { border: none; padding: 0px; }
.inner-counter li:before { font-style: normal; font-family: 'Catamaran'; font-weight: 500; font-size: 32px; line-height: 52px; color: #212529; }
.inner-counter li:before { position: absolute; left: 0px; top: 1px; border: none; padding: 0px; margin: 0px; }
.inner-counter li > h4 { margin-left: 30px; }
.roman-numeral-counter.inner-counter li:before { content: counter(roman-numeral-counter, upper-roman) ". "; }
.roman-numeral-counter.inner-counter li:before { position: absolute; content: counter(roman-numeral-counter, upper-roman) ". "; display: inline-block; left: 0px; top: 13px; height: auto; line-height: 33px; }
.type-of-accout-section-repeat { padding-left: 40px; }
.news-calender ol li { list-style: none; width: calc(100% / 6); padding: 15px; border-bottom: 1px solid #FCC758; text-align: center; border-right: 1px solid #FCC758; }
.news-calender ol { display: flex; flex-wrap: wrap; border: 1px solid #FCC758; border-radius: 20px; overflow: hidden; }
.news-years { text-align: center; max-width: 200px; margin: 0 auto; font-weight: 600; font-size: 28px; line-height: 35px; color: #212529; background: #FAAF11; border-radius: 10px 10px 0px 0px; padding: 5px 10px; }
.news-calender { margin-bottom: 40px; }
.news-table-main { padding-top: 70px; }

/* contact us */
ul.contact-card-wrap { display: flex; flex-wrap: wrap; justify-content: center; margin: 0px -10px; }
ul.contact-card-wrap li { width: calc(25% - 20px); margin: 0px 10px; text-align: center; padding: 20px; background: red; background: #FE4B7B; border-radius: 20px; flex: 1; position: relative; transition: all 300ms ease; }
ul.contact-card-wrap li a:before { content: ''; position: absolute; inset: 0; z-index: 9; background: transparent; }
ul.contact-card-wrap li:hover { box-shadow: 0px 9px 10px 2px rgb(0 0 0 / 20%); }
ul.contact-card-wrap li .contact-icon { width: 65px; height: 65px; background: #fff; border-radius: 100%; object-fit: contain; padding: 14px; margin: 0 auto; display: flex; align-items: center; justify-content: center; }
ul.contact-card-wrap li p { margin: 0px; color: #fff; font-style: normal; font-weight: 600; font-size: 20px; line-height: 25px; margin-top: 20px; }
ul.contact-card-wrap li a { font-weight: 500; font-size: 16px; line-height: 20px; text-align: center; color: #fff; }
.contact-page-card { padding: 70px 0px 0px; position: relative; }
.common-animation { position: relative; }
.common-animation:before { content: ''; position: absolute; top: 10px; left: 50%; background: url(./assets//images/shape1.png)no-repeat center / contain; width: 30px; height: 30px; text-align: center; animation: rotation 5s infinite linear; transform: translateZ(0); }
ul.contact-card-wrap li p { margin: 0px; color: #fff; font-style: normal; font-weight: 600; font-size: 20px; line-height: 25px; margin-top: 20px; }
ul.contact-card-wrap li a { font-weight: 500; font-size: 16px; line-height: 20px; text-align: center; color: #fff !important; }
ul.contact-card-wrap li.purple-card { background: #B250FE; }
ul.contact-card-wrap li.green-card { background: #5BD1A5; }
ul.contact-card-wrap li.yellow-card { background: #FAAF11; }
.common-animation-inner { position: relative; }
.common-animation-inner:before { content: ''; position: absolute; top: 100px; left: 100px; background: url(./assets/images/shape2.png)no-repeat center / contain; width: 30px; height: 30px; text-align: center; animation: rotation 8s infinite linear; transform: translateZ(0); }
.common-animation-inner:after { content: ''; position: absolute; bottom: 10px; right: 40px; background: url(./assets/images/shape5.png)no-repeat center / contain; width: 90px; height: 90px; text-align: center; animation: mover 1s infinite alternate; transform: translateZ(0); }
.contact-us .sign-up-form-wraper .sign-in-form-wraper { position: relative; }
.contact-us .sign-up-form-wraper .sign-in-form-wraper:after { content: ''; position: absolute; bottom: 90px; left: 0px; background: url(./assets/images//shape3.png)no-repeat center / contain; width: 50px; height: 50px; text-align: center; animation: mover 1s infinite alternate; transform: translateZ(0);}
.contact-us .sign-up-form-wraper .sign-in-form-wraper:before { content: ''; position: absolute; top: 90px; right: 0px; background: url(./assets/images//shape4.png)no-repeat center / contain; width: 50px; height: 50px; text-align: center; animation: mover 1s infinite alternate; transform: translateZ(0);}

/* omthly-contest */
.momthly-contest-prize { padding-top: 70px; }
.monthlt-contest-wrap { display: flex; flex-wrap: wrap; }
.monthly-image { width: calc(55% - 30px); margin: 0px 15px; }
.monthly-content { margin: 0px 15px; width: calc(45% - 30px); }
.monthly-image img { border-radius: 20px; }
.momthly-contest-prize { padding-top: 70px; }
.monthlt-contest-wrap { display: flex; flex-wrap: wrap; }
.monthly-image { width: calc(55% - 30px); margin: 0px 15px; }
.monthly-content { margin: 0px 15px; width: calc(45% - 30px); }
.monthly-content p:last-child { margin: 0px; }
.monthly-image img { border-radius: 20px; }
.monthly-content strong { font-style: normal; font-weight: 500; font-size: 28px; line-height: 32px;/* or 114% */ color: #23215B; display: block; font-family: 'Quicksand'; margin-bottom: 20px; }
.monthl-reminder { padding: 70px 0px 0px; }
.pop-monthly-reminder { background: #FEE7B8; border-radius: 15px; width: 652px; margin: 0 auto; padding: 20px; }
.pop-monthly-reminder { font-style: normal; font-weight: 600; font-size: 32px; line-height: 42px;/* or 131% */ text-align: center; color: #212529; margin-bottom: 40px; }
.monthly-form button.yellow-button { margin-top: 40px; }
.monthly-form form { margin-bottom: 60px; }
.monthly-reminder-wrap p a { color: #0092b8; }
.monthly-reminder-wrap p a:hover { color: #000; }
.contest-winners { padding: 70px 0px 0px; }
.contest-winners table { width: 100%; min-width: 900px; }
.monthly-reminder-wrap p a { color: #0092b8; }
.contest-winners .table-responsive { width: 100%; border-radius: 20px; overflow: hidden; border: 1px solid rgba(35, 33, 91, 0.1); display: block; }
.contest-winners table thead { background: rgba(35, 33, 91, 0.05); border-radius: 22px 22px 0px 0px; }
.contest-winners table thead td { padding: 15px; font-style: normal; font-weight: 600; font-size: 20px; line-height: 25px; color: #23215B; }
.contest-winners table tbody td { padding: 15px; }
.contest-winners  .table-responsive { border-radius: 20px; border: 1px solid rgba(35, 33, 91, 0.1); }
.contest-winners table td { border-right: 1px solid rgba(35, 33, 91, 0.1); border-bottom: 1px solid #ccc; }
.contest-winners table td:last-child { border-right: 0px; }
.contest-winners table tr:last-child td { border-bottom: 0px; }
.contest-winners table tdoby tr:last-child td { border-bottom: 0px; }
.avtar-details:before { content: ''; position: absolute; top: 30px; left: 0px; width: 30px; height: 30px; background: url(./assets//images/cought.png)no-repeat center / contain; right: 25px; left: auto; }
.common-animation-inner { padding: 70px 0px 0px; }

/* per month */
ul.per-month > li { width: 33.3%; padding-left: 0.6rem; padding-right: 0.6rem; margin-bottom: 1rem; display: flex; flex-direction: column; }
.contest-winners table thead tr { border-radius: 20px; overflow: hidden; }
ul.per-month { display: flex; flex-wrap: wrap; }
.per-month-card { background: #FFFFFF; border-radius: 20px; }
.per-month-card { background: rgba(35, 33, 91, 0.05); border-radius: 20px 20px 0 0; padding: 15px; border: 1px solid rgba(35, 33, 91, 0.1); display: flex; /*flex: 1 0 auto*/ }
/* ul.per-month li:first-child .per-month { border-radius: 20px 0px 0px 0px; overflow: hidden; }
ul.per-month > li:first-child .per-month-card { border-radius: 20px 0px 0px 0px; }
ul.per-month > li:last-child .per-month-card { border-radius: 0px 20px 0px 0px; } */
ul.per-month > li ul {display: flex; flex-direction: column; width: 100%; height: 100%; border: 1px solid rgba(35, 33, 91, 0.1); border-radius: 0 0 20px 20px;}
/* ul.per-month > li ul li { border: 1px solid rgba(35, 33, 91, 0.1); padding: 15px; } */
.per-month-wrap { width: 100%; background: #FFFFFF; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); border-radius: 20px; display: flex; flex-direction: column; }
.per-month-wrap p { display: block; margin: 0px; width: 100%; }
/*ul.per-month > li:first-child .per-month-card { border-radius: 20px 20px 0px 0px; }
ul.per-month > li:last-child .per-month-card { border-radius: 0px 20px 0px 0px; flex: 1 0 auto; }
ul.per-month > li:first-child li:last-child { border-radius: 0px 0px 0px 20px; }
ul.per-month > li:last-child li:last-child { border-radius: 0px 0px 20px; }*/
.per-month-tickets { min-height: 180px; position: relative; border-radius: 20px 20px 0px 0px; padding: 20px; display: flex; flex-wrap: wrap; justify-content: center; position: relative; }
.per-month-tickets:before { content: ''; position: absolute; top: inherit; left: 0px; width: 100%; background: url(./assets/images/per-tickets.png)no-repeat top / cover; height: 74px; bottom: 0px; }
.per-month-wrap > strong { display: block; width: 100%; font-weight: 600; font-size: 20px; line-height: 25px; color: #000000; }
.per-month-wrap > p { font-weight: 500; font-size: 18px; line-height: 32px; color: #23454E; }
.per-month-details { padding: 20px; display: flex; flex-wrap: wrap; max-height: 178px; overflow: auto; margin-bottom: 20px; scrollbar-width: thin; scrollbar-color: #2c9dd4 #888; }
.per-month-tickets strong { width: 100%; display: block; font-style: normal; font-weight: 900; font-size: 32px; line-height: 38px; color: #FFFFFF; }
.per-month-tickets span { color: #fff; font-weight: 500; font-size: 18px; line-height: normal; color: #FFFFFF; text-align: center;}
.per-month-details strong { margin-bottom: 10px; display: block; }
ul.per-month li ul li { font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #212529; padding-left: 60px; position: relative; }
ul.per-month li ul li:not(:last-child) { font-style: normal; font-weight: 600; font-size: 18px; color: #212529; padding-left: 60px; position: relative; min-height: 58px; padding: 6px; padding-left: 65px; display: flex; align-items: center; line-height: normal; overflow: hidden !important; border-bottom: 1px solid rgba(35, 33, 91, 0.1); }
ul.per-month li ul li:empty:before { display: none }
/* ul.per-month li ul li:before { content: ''; position: absolute; left: 0px; top: 50%; width: 30px; height: 30px; background: url(./assets/images/i.png)no-repeat center / contain; transform: translate(50%, -50%); } */
ul.per-month li ul li .info {position: absolute; left: 0px; top: 50%; width: 30px; height: 30px; transform: translate(50%, -50%); cursor: pointer;}
ul.per-month li ul li.per-month-button { position: static; padding: 15px; margin-top: auto; border-radius: 0 0 20px 20px; display: flex; align-items: center; justify-content: center; }
ul.per-month li ul li.per-month-button:before { display: none; }
.per-month-details a { margin: auto; margin-bottom: 0px; }
.per-month-details a { margin-top: auto; }
.per-month-details::-webkit-scrollbar { width: 8px; }
.per-month-details::-webkit-scrollbar-track { background: #888; }
.per-month-details::-webkit-scrollbar-thumb { background: #2c9dd4; }
.per-month-details .card:hover { box-shadow: none; }

/* my-account */
/* .tab-items .MuiTabs-scroller > div { display: flex; justify-content: space-between; } */
.account-tab { padding: 70px 0px 0px; }
.tab-items .MuiTabs-scroller > div button { font-family: 'Quicksand'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #212529; text-transform: capitalize; }
.tab-content .MuiBox-root { padding: 40px 0px 0px; }

/* .form-control:valid { background: #E6F4F8; color: #0092B8; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #0092B8; }
select.selected { background-color: #E6F4F8; color: #0092B8; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #0092B8; } */
.choose-icon { max-width: 300px; margin: 0 auto 40px; text-align: center; position: relative;}
.choose-icon-wraper { width: 80px; height: 80px; border-radius: 100%; margin: 0 auto 15px; position: relative; }
.account-details .form-group .choose-icon-wraper a { position: absolute; right: -12px; bottom: 0px; height: 25px; }
.choose-icon > p { font-style: normal; font-weight: 500; font-size: 20px; line-height: 25px; color: #212529; }
.choose-icon-wraper  img { border-radius: 100%; overflow: hidden; width: 100%; height: 100%; object-fit: cover; }
.choose-icon-wraper a { position: absolute; right: -12px; bottom: 0px; }
.choose-icon > p { font-style: normal; font-weight: 500; font-size: 20px; line-height: 25px; color: #212529; }
.account-details .form-group { position: relative; }
.account-details .form-group a { position: absolute; top: 0px; right: 10px; }
.account-details .form-group a img { width: 25px; }
.account-details .form-group .question-mark img { width: 16px; object-fit: contain; }
.common-pop.editDetail .modal-dialog { max-width: 600px; }
.form-group .question-mark { position: absolute; top: 0px; right: 10px; }
.form-group .question-mark img { width: 25px; }
.form-group .question-mark img { width: 16px; object-fit: contain; }
.form-group > .half-fuiel-main { position: relative; }
.form-group .half-fuied, form .full-fuied { position: relative; }
.common-pop.editDetail .modal-dialog .modal-title.h4 { font-size: 22px; }
.common-form form .form-text,
.common-pop .modal-body form .form-text,
.progress-report-wrap form .form-text,
.upload-worksheet-wrap form .form-text { width: 100%; min-height: 32px; font-size: .655em; line-height: normal; left: 0; bottom: -35px; position: absolute; display: block; }


/* my-children */
.child-wraper { padding: 70px 0px 0px; }
/* .my-table { background: rgba(35, 33, 91, 0.15); border-radius: 22px 22px 0px 0px; padding: 20px; display: flex; justify-content: space-between; font-family: 'Quicksand', sans-serif;}
.my-table h3 { margin: 0px; }
.my-table form { display: flex; position: relative; }
.my-table form input { padding-right: 3rem; background: #FFFFFF; border: 1px solid rgba(33, 37, 41, 0.8); border-radius: 5px 15px; min-width: 314px; max-height: 40px; position: relative; }
.my-table { background: rgba(35, 33, 91, 0.15); border-radius: 22px 22px 0px 0px; padding: 20px; display: flex; justify-content: space-between; }
.my-table h3 { margin: 0px; }
.my-table form { display: flex; overflow: hidden; border: 1px solid rgba(33, 37, 41, 0.8); border-radius: 5px 15px; min-width: 314px; max-height: 40px; position: relative; }
.my-table form input { background: #FFFFFF !important; border: none; height: 100%; }
.my-table strong { font-weight: 700; font-size: 22px; line-height: 28px; color: #212529; }
.my-table button { position: absolute; top: 0px; right: 0px; font-size: 0px; width: 40px; height: 38px; border: none; background: #F1F1F1; border-radius: 0px 15px 5px 0px; padding: 0px; margin: 0px !important; }
.my-table-wraper table { width: 100%; }
.my-table-wraper table td { padding: 20px; border: 1px solid rgba(35, 33, 91, 0.1); font-family: 'Quicksand', sans-serif; font-weight: 500;}
.my-table-wraper table td:not(:first-child) { text-align: center; }
.my-table-wraper table th:not(:first-child) { text-align: center; }
.my-table-wraper table tbody tr:last-child td:first-child { border-radius: 20px; overflow: hidden; }
.my-table-wraper table tbody tr:last-child td { color: #23454e; border: 1px solid rgba(35, 33, 91, 0.1) !important; border-bottom: 0px !important; font-family: 'Quicksand', sans-serif; font-weight: 500;}
.my-table-wraper table tbody tr td:first-child { border-left: 0px !important; }
.my-table-wraper table tbody tr td:last-child { border-right: 0px !important; }
.my-table-wraper { border-radius: 22px; border: 1px solid rgba(35, 33, 91, 0.1); overflow: hidden; } */
.add-child-button { margin-top: 30px; }
.add-child-button em { font-weight: 700; font-size: 16px; line-height: 28px; color: #23454E; display: flex; align-items: center; }
.add-child-button em a { color: #0092B8; margin-left: 6px; }
.inner-menu ul.hero-menu { margin-top: 0px; }
.inner-menu { padding: 70px 0px 30px; }
/* .table-responsive.common-table table thead th { font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #23215B; background: rgba(35, 33, 91, 0.05); font-family: 'Quicksand', sans-serif;} */

/* assign-lessons */
.assign-lessons-wraper { display: flex; flex-wrap: wrap; align-items: center; margin: 0px -25px; }
.assign-image { width: calc(60% - 50px); margin: 0px 25px; }
.assign-contne { width: calc(40% - 50px); margin: 0px 25px; position: relative; }
.assign-image img { border-radius: 20px; width: 100%; }
.assign-image img { border-radius: 20px; width: 100%; }
.assign-contne ul { display: flex; align-items: center; flex-wrap: wrap; }
.assign-contne ul li { width: 100%; }
.assign-image img { border-radius: 20px; width: 100%; }
.assign-contne ul { position: relative; display: flex; align-items: center; flex-wrap: wrap; }
.assign-image img { border-radius: 20px; width: 100%; }
.assign-contne ul { position: relative; display: flex; align-items: center; flex-wrap: wrap; }
.assign-contne ul li { width: 100%; margin-bottom: 40px; }
.assign-contne ul li:last-child { margin: 0px; }
.assign-contne ul li a { font-weight: 600; font-size: 36px; line-height: 45px; }
.assign-contne ul li a:hover { color: #0092B8; }
.assign-contne img { position: absolute; top: 50%; right: 0px; transform: translateY(-50%); height: 100%; }
.renewal-information-wrap { background: #FEE7B8; border-radius: 15px; max-width: 620px; margin: 0 auto; margin-top: 50px; text-align: center; padding: 20px; }
.renewal-information-wrap p { margin: 0px; }
.renewal-information-wrap strong { width: 100%; display: block; font-weight: 600; font-size: 24px; line-height: 28px;/* identical to box height, or 117% */ color: #212529; }
.renewal-information-wrap em { font-weight: 700; font-size: 20px; line-height: 23px; color: #23454E; margin-top: 15px; display: block; }
.assignments-main .my-table-wraper table thead td { text-align: center; }
.assignments-main .child-wraper > strong { margin-bottom: 30px; display: block; font-weight: 600; font-size: 24px; line-height: 30px; color: #23215B; }
.common-accordion-main { padding-top: 70px; }
.common-accordion-main { padding-top: 70px; }
.common-accordion-main > .container strong { display: block; margin-bottom: 40px; font-weight: 600; font-size: 24px; line-height: 30px; color: #23215B; }
.common-accordion-main .MuiAccordionSummary-root:before { top: 50%; right: 20px; transform: translateY(-50%); }
.common-accordion-main .MuiAccordionSummary-content { padding: 8px 20px; margin: 0px; }
.common-accordion-main .MuiAccordionSummary-content.Mui-expanded { background: #E6F4F8; margin: 0px }
/* .common-accordion-main .Mui-expanded .MuiAccordionSummary-root:before { top: 35px; } */

/* .common-accordion-main  .faq > div:last-child{border-bottom: 0px;} */
.common-accordion-repeat > div > div .MuiAccordionDetails-root { padding: 30px 0px; }
.common-accordion-wrap  .common-accordion-repeat:last-child > div { border: none; }
.common-accordion-wrap { border: 1px solid rgba(33, 37, 41, 0.2); border-radius: 10px; overflow: hidden; }
.common-accordion-wrap { border: 1px solid rgba(33, 37, 41, 0.2); border-radius: 10px; overflow: hidden; }
.MuiAccordionSummary-content span { display: block; font-weight: 500; font-size: 20px; line-height: 25px; color: #212529; }
.common-accordion-wraper-table table { width: 100%; }
.common-accordion-wraper-table table td { padding: 20px; border: 1px solid rgba(35, 33, 91, 0.1); }
.common-accordion-wraper-table table thead td { border-top: 0px; font-size: 20px; line-height: 25px; color: #23215B; font-weight: 600; }
.common-accordion-wraper-table table thead td:first-child { border-left: 0px; }
.common-accordion-wraper-table table thead td:last-child { border-right: 0px; }
.common-accordion-wraper-table table tbody td:last-child { border-right: 0px; }
.common-accordion-wraper-table table tbody td:first-child { border-left: 0px; }
.common-accordion-wraper-table table tbody tr:last-child td { border-bottom: 0px; }
.common-accordion-wraper-table { border: 1px solid rgba(35, 33, 91, 0.1); text-align: center; border-radius: 20px; overflow: hidden; max-width: 1100px; margin: 0 auto; }
.common-accordion-wraper-table table thead { background: rgba(35, 33, 91, 0.05); }

/*assignment*/
.assignment.common-pop .modal-dialog { max-width: 540px; }
.assignment.common-pop .modal-dialog ul.header-top-buttons li { width: 100%; }
.assignment.common-pop .modal-dialog a.yellow-button { width: max-content; padding: 10px 40px; margin: 0 auto; }
.assignment.common-pop .modal-dialog  ul li { display: block; margin-bottom: 20px; }
.overview-assignment thead tr { background: rgba(35, 33, 91, 0.05); }
.assignments-main .my-table-wraper .overview-assignment  table thead td { padding: 20px }
.assignments-main .my-table-wraper table td { font-size: 16px; }
.renewal-information > div  > a { text-align: center; display: block; font-weight: 600; font-size: 28px; line-height: 28px; color: #0092B8; margin-top: 30px; }
.renewal-information > div > a { font-size: 20px; }
.assign-contne ul li a:after { content: ''; width: 15px; height: 15px; border: 3px solid #FE4B7B; rotate: 230deg; display: inline-block; border-right: 0px; border-top: 0px; margin-left: 10px; margin-bottom: 3px; }
.assign-contne ul li a:hover:after { border-color: #0092B8 !important; }
.assign-contne ul li a.dark-blur-color:before { background: red; }
.assign-contne ul li a.dark-blur-color:after { border-color: #23215B; }
.assign-contne ul li a.green-color:after { border-color: #38BC83; }
.assign-contne ul li a.purple-color:after { border-color: #B250FE; }
.solve small.text-danger.form-text { display: block; width: 100%; position: absolute; bottom: -30px; left: 0px; }
.solve { position: relative; }
.membership-order-main { padding: 70px 0px 0px; }
.upcomingWebinars-main { padding: 70px 0px 0px; }
.link-wraper-only a { display: block; color: #0092B8; }
.table-of-content { padding: 70px 0px 0px; }
.intro-wraper h4 a { font-weight: 400; }
.intro-wraper > strong { display: block; width: 100%; margin-bottom: 10px; }
.intro-wraper > strong a { font-weight: 400; }
.links-inner strong { display: block; width: 100%; }
.links-inner { margin: 30px 0px 0px; }
.intro-wraper h4 a { font-weight: 400; }
.intro-wraper > strong { display: block; width: 100%; margin-bottom: 10px; }
.intro-wraper > strong a { font-weight: 400; }
.links-inner strong { display: block; width: 100%; margin-bottom: 20px; }
.links-inner { margin: 30px 0px 0px; }
.links-inner { display: flex; flex-wrap: wrap; }
.links-inner a { margin-right: 15px; }
.intro-wraper a { color: #0092B8; }
.intro-wraper a.yellow-button { color: #212529; }
.pages-name-details { padding: 70px 0px 0px; }
.pages-name-details-wrap strong > a { color: #0092B8; }
.mychildren { padding: 70px 0px 0px; }
.home-main .slick-track { display: flex !important; }
.home-main .slick-initialized .slick-slide { height: auto; }
.home-main .slick-initialized .slick-slide > div { height: 100%; }
.home-main .testimonial-slider-repeat { height: 100%; }
.home-main .testimonial-wrap { height: 100%; }
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root { padding: 0px !important; }
.faq-wraper { margin-bottom: 3rem; background: #FFFFFF; border: 1px solid rgba(33, 37, 41, 0.2); border-radius: 10px; overflow: hidden; }
.faq-wraper .common-accordion-repeat .MuiAccordionSummary-root:before { content: '+'; position: absolute; right: 15px; top: 50%; font-size: 32px; transform: translate(-50%, -50%); }
.faq-wraper .MuiAccordionSummary-gutters.Mui-expanded { min-height: 64px; background: #E6F4F8; }
.common-accordion-repeat:last-child > div { border: none; }
.faq-wraper .Mui-expanded .MuiAccordionSummary-root:before { transform: inherit; top: 50%; content: '-'; font-size: 53px; transform: translate(-50%, -50%); }
.faq-wraper .MuiAccordionSummary-content { padding: 20px 40px 20px 20px; margin: 0px !important; }
.faq-wraper .common-accordion-repeat > div > div .MuiAccordionDetails-root { padding: 20px; }
.teacher-traing-button { display: flex; flex-wrap: wrap; }
.teacher-traing-button > div { width: calc(50% - 30px); margin: 0px 15px; }
.teacher-traing-button > div a { margin-bottom: 15px; }
.teacher-training { padding: 70px 0px 0px; }

/*-------------- 18.11.22 [START] --------------*/
.signup-wrap { width: 100%; padding-top: 70px; padding-bottom: 30px; position: relative; }
.signup-wrap .section-title h5 { color: #212529; font-size: 32px; font-weight: 500; line-height: 48px; text-align: left; font-family: 'Quicksand', sans-serif; }
.signup-wrap .account-type { padding: 40px 0; position: relative; }
.signup-wrap .account-type a { width: 100%; padding: 2rem 1rem; border-radius: 15px; border: 1px solid #212529; text-align: center; box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05); transition: all 0.5s ease; display: inline-block; }
.signup-wrap .account-type h4 { color: #212529; margin: 0px; font-size: 28px; font-weight: 500; font-family: 'Quicksand', sans-serif; transition: all 0.5s ease; }
.signup-wrap .account-type p { color: rgba(33, 37, 41, 0.60); margin: 0px; font-size: 24px; font-weight: 500; font-family: 'Quicksand', sans-serif; }
.signup-wrap .info p { color: #212529; margin: 0px; font-size: 20px; font-weight: 500; font-family: 'Quicksand', sans-serif; }
.signup-wrap .account-type a:hover { border: 1px solid #0092B8; }
.signup-wrap .account-type a:hover h4 { color: #0092B8; }

/*-------------- 18.11.22 [END] --------------*/
span.MuiSwitch-root .MuiSwitch-track { padding: 0px; width: 140px; height: 44px; background: #F8F9FA; border: 1px solid #CED4DA; border-radius: 15px; position: relative; }
span.MuiSwitch-root { width: auto; height: auto; padding: 0px; width: 140px; height: 44px; background: #F8F9FA; border: 1px solid #CED4DA; border-radius: 15px; position: relative; margin-left: auto; font-family: 'Quicksand', sans-serif;}
span.MuiTouchRipple-root { position: absolute; top: 0px; left: 0px; border: 0px; width: 50%; }
span.MuiSwitch-thumb { position: absolute; top: 0px; left: 0px; border: 0px; width: 100%; border-radius: 0px; height: 100%; background: transparent; }
span.MuiButtonBase-root.MuiSwitch-switchBase { width: 100%; height: 100%; border-radius: 0px; }
/* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked { -webkit-transform: translateX(50%) !important; -moz-transform: translateX(50%) !important; -ms-transform: translateX(50%) !important; transform: translateX(50%) !important; }
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked { transform: inherit !important; } */
.email-setting .switch .MuiSwitch-switchBase.Mui-checked{ -webkit-transform: translateX(50%) !important; -moz-transform: translateX(50%) !important; -ms-transform: translateX(50%) !important; transform: translateX(50%) !important; }
.email-setting .switch .MuiSwitch-switchBase.Mui-checked { transform: inherit !important; }
.MuiSwitch-track { background-color: transparent !important; }
span.MuiSwitch-thumb:before { content: "On"; width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; z-index: 9; font-weight: 500; font-size: 22px; line-height: 28px; color: rgba(33, 37, 41, 0.5); z-index: 0; position: absolute; top: 0px; left: 0px; background: #198754; color: #fff; transition: all 300ms ease; }
span.MuiSwitch-thumb:after { content: "Off"; width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; z-index: 9; font-weight: 500; font-size: 22px; line-height: 28px; right: 0px; color: rgba(33, 37, 41, 0.5); z-index: 0; position: absolute; top: 0px; transition: all 300ms ease; }
/* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb:before { background: transparent; color: rgba(33, 37, 41, 0.5); }
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb:after { background: #198754; color: #fff } */
.email-setting .switch .MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb:before { background: transparent; color: rgba(33, 37, 41, 0.5); }
.email-setting .switch .MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb:after { background: #198754; color: #fff }

.tab-wraper { padding: 80px 0px; }
.tab-wraper .MuiTabs-root { width: 40%; }
.tab-wraper .tab-contain-wrap { width: 60%; }
.tab-wraper .MuiTabs-vertical button { width: 100%; max-width: 100%; text-align: left; align-items: baseline; border-bottom: 1px solid rgba(33, 37, 41, 0.1); min-height: auto; padding: 15px; font-weight: 500; font-size: 22px; line-height: 28px; color: #212529; }
.tab-wraper > div > div { background: #FFFFFF; border: 1px solid #FE4B7B; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); border-radius: 20px; }
.tab-wraper .MuiTabs-vertical button:last-child { border: none; }


.common-lessions-section { padding: 120px 0px 0px; }
.common-lessions-wrap { -moz-column-count: 3; column-count: 3; -webkit-column-count: 3; }
.common-lessions-repeat { -webkit-column-break-inside: avoid; page-break-inside: avoid; break-inside: avoid; }
.common-lessions-repeat { -webkit-column-break-inside: avoid; page-break-inside: avoid; break-inside: avoid; margin-bottom: 20px; background: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.2) }
.common-lessions-repeat:hover { border-color: #0092B8; }
.lessions-image img { width: 100%; object-fit: contain; }
.common-lessions-repeat ul li button { font-weight: 500; font-size: 18px; line-height: 22px; color: #212529; text-transform: capitalize; padding: 10px; border-radius: 0px; width: 100%; text-align: left; justify-content: flex-start; border-bottom: 1px solid rgba(33, 37, 41, 0.1); }
.common-lessions-repeat ul { padding: 10px 0px; }
.common-lessions-repeat ul li:last-child button { border: none; }
.common-lessions-repeat:hover { transform: translateY(-5px); }
.common-lessions-repeat { transition: all 300ms ease; cursor: pointer; }
.common-lessions-repeat { margin-bottom: 80px; }

.MuiTooltip-tooltip { border: 2px solid #80C9DC#80C9DC; }
.MuiTooltip-tooltip { background: #FFFFFF; border: 1px solid #80C9DC; box-shadow: 0px 4px 15px rgba(128, 201, 220, 0.1); border-radius: 15px; }
.css-kudwh-MuiTooltip-arrow { color: #7fc9dc !important; width: 23px; }
.lessions-image h4 { font-size: 22px; padding: 10px 20px; margin: 0px; }


.lessions-image { display: flex; flex-direction: column; flex-direction: column-reverse; }
.lessions-image img { max-width: calc(100% - 30px); margin: 0 auto; margin-top: -48px; }
.common-lessions-inner { padding: 80px 0px 0px; }
.common-lessions-inner-repeat { border-radius: 20px; border: 1px solid rgba(35, 33, 91, 0.1); overflow: hidden; }
.title-main { padding: 20px; padding: 10px 20px; font-style: normal; font-weight: 600; font-size: 20px; line-height: 25px; color: #23215B; background: rgba(35, 33, 91, 0.05); }
.title-main h4 { margin: 0px; }
.common-lessions-inner-repeat ul li { padding: 10px 20px; border-bottom: 1px solid #ccc; display: flex; flex-wrap: wrap; justify-content: space-between; }
.common-lessions-inner-repeat ul li button { font-family: 'Quicksand', sans-serif; font-style: normal; color: #545555; font-size: 20px; font-weight: 500; line-height: 32px; letter-spacing: 0; padding: 0px; }
.tooltip-links a { color: #FFFFFF; width: 40px; height: 40px; margin: 0px 5px; border-radius: 100%; display: flex; align-items: center; justify-content: center; background: #faaf11; }
.tooltip-links a.assign-lesson { background: #13a89f; }
.tooltip-links a.assigned-lesson { background: #ca587e; }
.tooltip-links { margin: 0px -5px; display: flex; align-items: center; }
.common-lessions-inner-repeat ul li:last-child { border: none; }
.tooltip-links a span { width: 17px; height: 9px; border: 2px solid #FFFFFF; rotate: 320deg; border-top: 0px; border-right: 0px; position: relative; top: -3px; }
.task-main > div > h4 { padding: 18px 35px; background: rgba(233, 233, 239, 0.7); border: 1px solid #9190AD; font-weight: 600; font-size: 28px; line-height: 35px; color: #23215B; margin-bottom: 42px; }
.task-main { padding: 80px 0px 0px; }
.task-wrap { display: flex; flex-wrap: wrap; margin: 0px -10px; }
.perform { width: calc(25% - 20px); margin: 0px 10px; background: #FFFFFF; box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); border-radius: 10px; }
.task-main > div > h4 {   padding: 18px 35px; background: rgba(233, 233, 239, 0.7); border: 1px solid #9190AD; font-weight: 600; font-size: 28px; line-height: 35px;    /* identical to box height */ color: #23215B; margin-bottom: 42px; }
.task-main { padding: 80px 0px 0px; }
.display-buttons { margin-top: 60px; display: flex; align-items: center; justify-content: center; }
.display-buttons > a { background: #FFFFFF; border: 2px dashed #FAAF11; border-radius: 10px; padding: 8px 20px; text-align: center; margin: 0px 40px; min-width: 100px; min-height: 100px; transition: all 300ms ease; }
.display-buttons > a:hover { background: #FAAF11; border: 2px solid  #FAAF11; color: #212529; }
.display-buttons > a strong { width: 100%; display: block; font-style: normal; font-weight: 600; font-size: 48px; line-height: 48px; color: #212529; }
.common-in-progress { padding: 20px; border-bottom: 1px solid rgba(33, 37, 41, 0.1); }
.common-in-progress:last-child { border: none; }
.common-in-progress span { width: 100%; font-weight: 600; font-size: 20px; line-height: 25px; color: #23215B; margin-bottom: 20px; display: block; }
.common-in-progress .CircularProgressbar { width: 40%; margin: 0 auto; }
.progress-bar.common-in-progress img { max-width: 200px; margin: 0 auto; }
.common-in-progress { text-align: center; }
.common-in-progress img { max-width: 200px; display: block; margin: 0 auto; }
/* .common-in-progress video { width: 50%; } */
label.form-label span { color: #dc3545; margin: 0 4px;}
.account-details.student-pop .form-group.half-fuied-main > div { margin-bottom: 25px; }
.student-pop-footer { display: flex; align-items: center; justify-content: space-between; }
.student-pop-footer i { color: #dc3545; }
.choose-image input { opacity: 0; }
.choose-image input + label { background: #FFFFFF; border-radius: 15px; height: 56px; display: block; width: 100%; padding: 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; color: #212529; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; appearance: none; transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; line-height: 38px; }
.common-pop-footer { display: flex; align-items: center; justify-content: center; padding-top: 30px; border-top: 1px solid #ccc; position: absolute; bottom: 30px; width: auto; max-width: initial; left: -40px; right: -40px; bottom: 0px; }
.common-pop-footer > button { margin: 0px 15px; }
.account-details.footer-position { position: relative; padding-bottom: 120px; }
section.common-section.commons-section .common-section-image { -webkit-mask-image: url("./assets/images/shape-maskone.png"); -webkit-mask-size: 100%; -webkit-mask-repeat: no-repeat; mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: contain; }
section.common-section.commons-section .common-section-image img { width: 100%; height: 100%; object-fit: cover; }
.addClass .modal-dialog { max-width: 600px; }
.changeuser-pop-main { display: flex; flex-wrap: wrap; margin: 0px -15px; }
.changes-user-pop { width: calc(25% - 30px); margin: 0px 15px 25px; }
.changes-user-pop:nth-child(4n) {
    /* margin-bottom: 0px; */
}
.changes-user-pop img { width: 200px; height: 200px; object-fit: cover; overflow: hidden; border: 8px solid white; box-shadow: 0px 0px 0px 4px #0092b8; border-radius: 100%; margin: 0 auto; display: block; transition: all 300ms ease; cursor: pointer; }
.changes-user-pop strong { text-align: center; margin-top: 10px; width: 100%; display: block; }
.changes-user-pop:hover img { border-width: 2px; }
.number { display: none; }
.number.active { display: block; }
.custom-accordion-wrap > div { margin: 20px 0px 0px; width: 100%; }
.select-student-all { flex-direction: inherit; justify-content: space-between; }
.number { padding: 20px; border: 1px solid; }
.select-student-all label { margin: 0px; background: #0092b8; padding: 10px; border-left: 2px solid white; width: 50%; color: #fff; }
.select-student-all span.MuiCheckbox-root { padding: 0px; margin-right: 10px; }
.select-student-all span.MuiCheckbox-root svg path { fill: #dff; }
.inner-title { margin-bottom: 15px; }
.assign-assignment .modal-dialog { max-width: 600px; }
.accordion-custom:not(:first-child) { margin-top: 20px; }
.custom-accordion-data-picker-wrap { display: flex; flex-wrap: wrap; align-items: center; margin: 0px -5px; }
.custom-accordion-data-picker-wrap > span { margin: 0px 5px; width: calc(10% - 10px); display: block; text-align: center; }
.custom-accordion-data-picker-wrap > form { margin: 0px 5px; width: calc(45% - 10px); }
.custom-accordion-data-picker-wrap > form > div { width: 100%; }
.select-student-all { display: flex; flex-direction: inherit; }


/*------------- 06.12.22 [START] -------------*/
.sub-category {
    margin-bottom: 2rem;
    display: block;
    -webkit-transition: all 1s;
    transition: all 1s;
}
.sub-category:last-child {
    margin-bottom: 0rem;
}
.sub-category:nth-child(odd) .border-primary {
    border: 2px solid #FBBF02 !important;
}
.sub-category:nth-child(even) .border-primary {
    border: 2px solid #13A89F !important;
}
.sub-category:nth-child(odd) .border-primary .img-wrap {
    border-right: 2px solid #FBBF02;
}
.sub-category:nth-child(even) .border-primary .img-wrap {
    border-right: 2px solid #13A89F;
}
.sub-category .card {
    border-radius: 15px;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
}
.sub-category .card .card-body {
    flex: 1;
}
.sub-category .card .img-wrap {
    width: 537px;
    height: 238px;
    background-color: #F8F8FF;
}
.sub-category .card .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.sub-category .card-body .card-title {
    color: #23454E;
    margin: 0px;
    font-weight: 600;
    font-size: 24px;
}
.sub-category:hover {
    transition: all .5s;
    transform: translateY(-10px);
}
.sub-category:hover .card-body .card-title {
    color: #0092B8;
}


#menu-number_of_questions .MuiPopover-paper,
#menu-lesson_time .MuiPopover-paper {
    max-height: 300px;
}

@keyframes wave {
    0% {transform: translateX(0);}
    25% {transform: translateX(-25%);}
    50% {transform: translateX(-50%);}
    75% {transform: translateX(-25%);}
}
/*------------- 06.12.22 [END] -------------*/


/*------------- 12.12.22 [START] -------------*/
.renew-wrap {
    padding: 70px 0 0;
    position: relative;
}
.renew-wrap .form-wrap {
    border-radius: 20px;
    box-shadow: 0 0 40px 5px rgb(0,0,0,0.05);
    padding: 30px;
}
.renew-wrap .form-group.half-fuied-main>div,
.renew-wrap .form-group.third-fuied-main>div {
    margin-bottom: 25px;
}
.renew-wrap .renew-button a + a {
    margin-left: 1.5rem;
}
.renew-wrap .total-cost {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
/* .renew-wrap .total-cost .form-label {
    font-size: 18px;
    font-weight: 600;
} */
.renew-wrap .total-cost .price {
    color: #0092B8;
    margin: 0px;
    height: 56px;
    border: 1px solid #CED4DA;
    font-style: normal;
    border-radius: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;    
    opacity: 1;
    display: flex;
    align-items: center;
    background: #E6F4F8;
}
.renew-wrap .col-lg-5 {
    margin-bottom: 3rem;
}
.renew-wrap .form-wrap form > .half-fuied-main:first-child {
    position: relative;
}
.renew-wrap .form-wrap form > .half-fuied-main:first-child:after {
    content: '';
    width: 100%;
    border: 1px solid rgba(0,146,184,0.2);
    left: 0;
    bottom: 10px;
    position: absolute;
}
/*------------- 12.12.22 [END] -------------*/

/*------------- 14.12.22 [START] -------------*/
.student-assignments-wrap {
    padding-top: 70px;
    position: relative;
}
.student-assignments-wrap .assignments-list {
    margin-bottom: 1rem;
    display: block;
    position: relative;
}
.student-assignments-wrap .assignments-list .card {
    border: 1px solid #CED4DA;
    transition: all 1s;
    overflow: hidden;
}
.student-assignments-wrap .assignments-list .card .card-body {
    min-height: 105px;
}
.student-assignments-wrap .assignments-list .img-wrap {
    width: 100%;
    height: 190px;
    background-color: #F8F8FF;
}
.student-assignments-wrap .assignments-list .img-wrap .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.student-assignments-wrap .assignments-list .card-title {
    color: #23454E;
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
    transition: all 1s;
}
.student-assignments-wrap .assignments-list .card-body .card-title:first-child {
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.student-assignments-wrap .assignments-list .card-body .card-title:last-child {
    color: #b08601;
}
.student-assignments-wrap .assignments-list .card:hover {
    border: 1px solid #0092B8;
}
.student-assignments-wrap .assignments-list .card:hover .card-title {
    color: #0092B8;
}

.student-assignments-wrap .level-category {
    margin-bottom: 2rem;
    display: block;
}
.student-assignments-wrap .level-category:last-child {
    margin-bottom: 0rem;
}
.student-assignments-wrap .level-category .card {
    border-radius: 15px;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    border: 1px solid #CED4DA;
    transition: all 1s;
}
.student-assignments-wrap .level-category .card .img-wrap {
    width: 437px;
    border-right: 1px solid #CED4DA;
    background-color: #F8F8FF;
    transition: all 1s;
}
.student-assignments-wrap .level-category .card .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.student-assignments-wrap .level-category .card-body .card-title {
    color: #23454E;
    margin: 0px;
    font-weight: 600;
    font-size: 20px;
    transition: all 1s;
}
.student-assignments-wrap .level-category .card:hover {
    border: 1px solid #0092B8;
}
.student-assignments-wrap .level-category .card:hover .img-wrap {
    border-right: 1px solid #0092B8;
}
.student-assignments-wrap .level-category .card:hover .card-title {
    color: #0092B8;
}
.student-assignments-wrap .badge-img,
.worksheet-list-wrap .worksheet-img {
    margin-bottom: 1rem;
    border: 1px solid #CED4DA;
    border-radius: 15px;
    overflow: hidden;
}
.student-assignments-wrap .badge-img .img-box,
.worksheet-list-wrap .worksheet-img .img-wrap {
    width: 100%;
    height: 200px;
    position: relative;
}
.student-assignments-wrap .badge-img .img-box img,
.worksheet-list-wrap .worksheet-img .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.student-assignments-wrap .badge-img h4,
.worksheet-list-wrap .worksheet-img h4 {
    color: #23454E;
    margin: 0px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
}
.worksheet-list-wrap {
    padding-top: 70px;
    position: relative;
}
.worksheet-list-wrap .worksheet-img {
    display: block;
}
.worksheet-list-wrap .worksheet-img .img-wrap img {
    object-fit: cover;
}


.my-setting-form { padding: 70px 0px 0px; }
.my-setting-inner { padding: 30px; background: #FFFFFF; box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); border-radius: 20px; }
.my-setting-inner .frm-details {width: 100%; margin-bottom: 2rem; flex-direction: row; align-items: center;}
.my-setting-inner .frm-details > label { /*width: 16%;*/ color: #23454E; margin: 0px; font-weight: 600; font-size: 20px; flex: 1; font-family: 'Quicksand', sans-serif;}
.my-setting-inner .frm-details .custom-radio { flex-direction: row; flex: 5;}
.my-setting-inner .frm-details .custom-radio label { margin: 0px 15px; }
.my-setting-inner .frm-details .custom-radio label .Mui-checked + span { background: #0092B8; color: #fff; }
.my-setting-inner .frm-details .custom-radio label .MuiRadio-colorPrimary { display: none !important; }
.my-setting-inner .frm-details .custom-radio span { padding: 10px 30px; border: 1px solid #0092B8; border-radius: 10px; font-size: 18px; width: 100%; height: 50px; display: flex; align-items: center; justify-content: center; color: #0092B8; font-family: 'Quicksand', sans-serif;}
.my-setting-inner .MuiFormControl-rooAt div label span:before { display: none; }
/* .my-setting-inner .MuiButtonBase-root { display: none !important; } */
.my-setting-form .my-setting-buttons {display: flex; justify-content: flex-end; margin: 20px -15px;}
.my-setting-form .my-setting-buttons button {margin: 0px 15px;}

.assignment-detail-wrap {
    padding-top: 70px;
    position: relative;
}
.assignment-detail-wrap .assigment-info {
    margin-bottom: 3rem;
}
.assignment-detail-wrap .assigment-info .img-wrap {
    width: 100%;
    height: 185px;
    background-color: #F8F8FF;
}
.assignment-detail-wrap .assigment-info .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.assignment-detail-wrap .assigment-info ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.assignment-detail-wrap .assigment-info ul li {
    width: 50%;
    color: #212529;
    padding: 0 15px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 1.5rem;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}
.assignment-detail-wrap .assigment-info ul li span {
    width: 100%;
    color: #0092B8;
    font-weight: 700;
    display: block;
}
.assignment-detail-wrap .assigment-button {
    margin-top: 2rem;
}
.assignment-detail-wrap .assigment-button a {
    font-size: 18px;
}
.assignment-detail-wrap .assigment-info ul li .date-info {
    width: 100%;
    display: flex;
    position: relative;
}
.assignment-detail-wrap .assigment-info .date-info span {
    width: auto;
}
.assignment-detail-wrap .assigment-info .date-info span.date {
    color: #212529;
    font-weight: 500;
    padding-left: 0.5rem;
}
/*------------- 14.12.22 [END] -------------*/


/*------------- 15.12.22 [START] -------------*/
.progress-wrap {
    padding-top: 70px;
    position: relative;
}
.progress-wrap .left-area {
    margin-bottom: 3rem;
}
.progress-wrap .left-area .custom-link {
    width: 100%;
    padding: 0.8rem 1.5rem;
    margin-bottom: 1rem;
    color: #FFFFFF;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-transform: capitalize;
    display: block;
    box-shadow: 0px 0px 40px 5px rgb(0,0,0,0.05);
    border-radius: 10px;
    background-color: #0092b8;
}
.progress-wrap .left-area .custom-link:hover {
    color: #212529;
    background-color: #FAAF11;
}
.progress-wrap .main-content .chart-info {
    width: 100%;
    color: #212529;
    padding: 30px;
    margin-bottom: 1.5rem;
    font-family: Quicksand;
    font-size: 16px;
    box-shadow: 0px 0px 40px 5px rgb(0,0,0,0.05);
    border-radius: 10px;
    background-color: #FFFFFF;
    position: relative;
}
.progress-wrap .main-content .chart-info img {
    width: 100%;
}
/*------------- 15.12.22 [END] -------------*/

/*------------- 16.12.22 [START] -------------*/
@keyframes Flower{0%{transform:translateX(0)}25%{transform:translateX(-40px)}50%{transform:translateX(0)}75%{transform:translateX(40px)}100%{transform:translateX(0)}}
@keyframes star{0%{transform:translateX(0)}25%{transform:translateY(-40px)}50%{transform:translateY(0)}75%{transform:translateY(40px)}100%{transform:translateY(0)}}
@keyframes line{0%{transform:translateX(0)}25%{transform:translateX(40px)}50%{transform:translateX(0)}75%{transform:translateX(-40px)}100%{transform:translateX(0)}}
@keyframes car{0%{transform:translate(0,0)}20%{transform:translate(40px,-5px)}40%{transform:translate(60px,40px)}60%{transform:translate(40px,60px)}80%{transform:translate(-40px,60px)}100%{transform:translate(0,0)}}
/*------------- 16.12.22 [END] -------------*/

/*------------- 17.12.22 [START] -------------*/
@keyframes flowerhome{0%{transform:translateX(0)}25%{transform:translateY(40px)}50%{transform:translateY(0)}75%{transform:translateY(-40px)}100%{transform:translateY(0)}}
@keyframes stars{0%{transform:translateX(0)}25%{transform:translateY(40px)}50%{transform:translateY(0)}75%{transform:translateY(-40px)}100%{transform:translateY(0)}}
@keyframes circles{0%{transform:translateX(0)}25%{transform:translateY(-40px)}50%{transform:translateY(0)}75%{transform:translateY(40px)}100%{transform:translateY(0)}}
@keyframes circle{0%{transform:translate(0,0)}20%{transform:translate(-30px,40px)}40%{transform:translate(60px,60px)}60%{transform:translate(70px,40px)}80%{transform:translate(40px,-70px)}100%{transform:translate(0,0)}}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes light {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
/*------------- 17.12.22 [END] -------------*/


/*------------- 18.12.22 [START] -------------*/
.progress-report-wrap {
    padding-top: 70px;
    position: relative;
}
.progress-report-wrap .box-wrap {
    width: 100%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 5px rgb(0,0,0,0.05);
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
/* .progress-report-wrap .report-img-wrap {
    width: 25%;
} */
.progress-report-wrap .report-img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    background-color: #FFFFFF;
}
/* .progress-report-wrap form.report-form {
    width: 75%;
    padding-left: 4rem;
} */
.progress-report-wrap .form-group.half-fuied-main > div {
    margin-bottom: 25px;
}
.progress-report-wrap .frm-bottom {
    width: 100%;
    position: relative;
}
.progress-report-wrap .frm-bottom .form-label {
    margin-bottom: 1.5rem;
    font-weight: 700;
}
.progress-report-wrap .custom-accordion-data-picker-wrap .MuiOutlinedInput-notchedOutline {
    border-color: #CED4DA;
    border-radius: 15px;
}
/*------------- 18.12.22 [END] -------------*/

/*------------- 20.12.22 [START] -------------*/
.error-img {
    padding-top: 70px;
    position: relative;
}
.error-img img {
    width: 550px;
}
/*------------- 20.12.22 [END] -------------*/



/*------------- 21.12.22 [START] -------------*/
.award-sec-wrap .upcomingWebinars-main-wraper .my-table form {
    border: none;
}
.award-sec-wrap .upcomingWebinars-main-wraper .my-table form > div {
    width: 100%;
}
.award-sec-wrap form .form-select {
    height: auto;
    border-radius: 5px 15px;
    border: 1px solid rgba(33, 37, 41, 0.8);
    background-color: #FFFFFF;
}
.award-sec-wrap .common-accordion-main .MuiAccordionSummary-content {
    padding: 20px 40px 20px 20px;
}
.award-sec-wrap .common-accordion-wrap .my-table-wraper {
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
}
.award-sec-wrap .common-accordion-main > .container strong {
    margin-bottom: 0px;
}
.award-sec-wrap .common-accordion-wrap .my-table-wraper form {
    display: none;
}
/*------------- 21.12.22 [END] -------------*/


/*------------- 22.12.22 [START] -------------*/
.modal-dialog .modal-disable {
    opacity: 0.3;
    pointer-events: none;
    position: relative;
}
/*------------- 22.12.22 [END] -------------*/


/*------------- 24.12.22 [START] -------------*/
.award-wrapper {
    padding-top: 70px;
}
.award-wrapper .awards-list .img-wrap {
    width: 100%;
    height: 250px;
    padding: 0.5rem;
    background-color: #F8F8FF;
    position: relative;
}
.award-wrapper .awards-list .img-wrap img {
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.award-wrapper .awards-list .card {
    border: 1px solid #CED4DA;
    margin-bottom: 1rem;
    transition: all 1s;
}
.award-wrapper .awards-list .card-title {
    color: #23454E;
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
    transition: all 1s;
}
.award-wrapper .awards-list .card:hover {
    border: 1px solid #0092B8;
}
.award-wrapper .awards-list .card:hover .card-title {
    color: #0092B8;
}
.terms-details .terms-info h2 {
    font-size: 32px;
    line-height: normal;
}
.contact-sec-wrap .common-form .yellow-button {
    margin-top: 3rem;
}
/*------------- 24.12.22 [END] -------------*/


/*------------- 25.12.22 [START] -------------*/
.individual_assignment_tbl .common-accordion-main > .container > strong {
    margin-bottom: 30px;
}
.child-wraper > strong,
.award-sec-wrap .account-tab strong,
.student-progress .table > strong {
    display: block;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #23215B;
}
/*------------- 25.12.22 [END] -------------*/


/*------------- 27.12.22 [START] -------------*/
.child-wraper .my-table-wraper table tbody tr td:first-child .lesson-icon,
.account-tab .my-table-wraper table tbody tr td:first-child .lesson-icon {
    height: 100px;
}
.child-wraper .my-table-wraper table tbody tr td.assign-detail,
.account-tab .my-table-wraper table tbody tr td.assign-detail,
.assignment-detail-wrap .my-table-wraper table tbody tr td.assign-detail,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td.lessons-detail,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td.assign-detail,
.progress-report-wrap .my-table-wraper table tbody tr td.assign-detail,
.progress-report-wrap .my-table-wraper table tbody tr td.more-link {
    font-weight: 600;
    background-color: #e6f4f8;
    cursor: pointer;
}
.child-wraper .my-table-wraper table tbody tr td.assign-detail:hover,
.account-tab .my-table-wraper table tbody tr td.assign-detail:hover,
.assignment-detail-wrap .my-table-wraper table tbody tr td.assign-detail:hover,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td.lessons-detail:hover,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td.assign-detail:hover,
.progress-report-wrap .my-table-wraper table tbody tr td.assign-detail:hover,
.progress-report-wrap .my-table-wraper table tbody tr td.more-link:hover {
    color: #0092B8;
}
.assignment-detail-wrap .my-table-wraper table thead tr th:last-child,
.assignment-detail-wrap .my-table-wraper table tbody tr td:last-child {
    text-align: left;
}
.assignment-detail-wrap .my-table-wraper table tbody tr td .assign:hover,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .more-link:hover,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .progress-lesson:hover {
    color: #4a88da;
}
.assignment-detail-wrap .my-table-wraper table tbody tr td .re-assign:hover {
    color: #FAAF11;
}
.assignment-detail-wrap .my-table-wraper table tbody tr td .remove:hover {
    color: #e04f5f;
}
.assignment-detail-wrap .my-table-wraper table tbody tr td a img,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .more-link img,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .progress-lesson img {
    margin-right: 0.5rem;
}
/*------------- 27.12.22 [END] -------------*/

/*------------- 28.12.22 [START] -------------*/
.award-sec-wrap .common-accordion-main > .container > strong {
    margin-bottom: 30px;
}
.sketchBoard .modal-body button svg {
    fill: #FAAF11;
}
/*------------- 28.12.22 [END] -------------*/


/*------------- 29.12.22 [START] -------------*/
.lesson-report-wrap,
.progress-report-wrap {
    padding-top: 70px;
    position: relative;
}
.lesson-report-wrap .lesson-info .details,
.lesson-report-wrap .lesson-info .score,
.lesson-report-wrap .lesson-info .question-answer,
.lesson-report-wrap .lesson-info .more-info {
    padding: 0px;
    margin-bottom: 2rem;
    border: 1px solid rgba(35, 33, 91, 0.1);
    border-radius: 10px;
}
.lesson-report-wrap .lesson-info .sub-title {
    font-size: 26px;
    padding: 15px;
    margin: 0px;
    line-height: 32px;
    border-radius: 10px 10px 0 0;
    background-color: #e6f4f8;
}
.lesson-report-wrap .lesson-info ul,
.progress-report-wrap ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.lesson-report-wrap .lesson-info ul li,
.progress-report-wrap ul li {
    width: 33.33%;
    color: #212529;
    padding: 15px;
    font-size: 18px;
    line-height: 30px;
    margin: 0px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}
.lesson-report-wrap .lesson-info ul li span,
.progress-report-wrap ul li span {
    width: 100%;
    color: #0092B8;
    font-weight: 700;
    display: block;
}
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .more-link,
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td .progress-lesson {
    cursor: pointer;
}
.upcomingWebinars-main-wraper .my-table-wraper table tbody tr td span.percentage,
.student-progress .my-table-wraper table tbody tr td span.percentage {
    width: 100%;
    padding-bottom: 0.3rem;
    display: block;
}
.progress-report-wrap ul {
    padding: 0px;
    margin-bottom: 2rem;
    border: 1px solid rgba(35, 33, 91, 0.1);
    border-radius: 10px;
}
/*------------- 29.12.22 [END] -------------*/


/*------------- 30.12.22 [START] -------------*/
.lesson-report-wrap .question-answer ul li .yellow-button {
    font-size: 18px;
    padding: 5px 30px;
    display: inline-block;
    height: auto;
    cursor: pointer;
}
.lesson-report-wrap .question-answer ul li .info {
    width: 100%;
    margin-bottom: 0.5rem;
    display: block;
}
/* .lesson-report-wrap .question-answer .info-btn {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
}
.lesson-report-wrap .question-answer .info-btn a {
    height: auto;
    font-size: 18px;
    padding: 5px 30px;
    display: inline-block;
} */
.progress-report-wrap .my-table-wraper .my-table > form {
    display: none;
}
.progress-wrap .chart-info .question-info ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.progress-wrap .chart-info .question-info ul li {
    width: 40%;
    color: #212529;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    border: 1px solid rgba(35, 33, 91, 0.1);
    border-radius: 10px;
}
.progress-wrap .chart-info .question-info ul li span {
    width: 100%;
    color: #0092B8;
    font-size: 16px;
    font-weight: 500;
    display: block;
    text-transform: initial;
}
.progress-wrap .chart-info strong {
    color: #212529;
    font-size: 18px;
    margin-bottom: 1rem;
    display: block;
}
.progress-wrap .chart-info .chart-data-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.progress-wrap .chart-info .chart-data-wrap .piechart {
    width: 60%;        
}
.progress-wrap .chart-info .chart-data-wrap .barchart {
    width: 40%;        
}
.badge-img-wrap .img-box {
    width: 100%;
    height: 350px;
    margin-bottom: 1.5rem;
    position: relative;
}
.badge-img-wrap .img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
/*------------- 30.12.22 [END] -------------*/


/*------------- 03.01.23 [START] -------------*/
.student-progress {
    padding-top: 70px;
    position: relative;
}
.student-progress .badge,
.student-progress .piechart {
    width: 100%;
    padding: 0px;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(35, 33, 91, 0.1);
    border-radius: 20px;
    position: relative;
}
.student-progress .badge h4,
.student-progress .piechart h4 {
    font-size: 26px;
    padding: 15px;
    margin: 0px;
    line-height: 32px;
    text-align: left;
    border-radius: 10px 10px 0 0;
    background-color: #e6f4f8;
}
.student-progress .badge ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}
.student-progress .badge ul li {
    width: 33.33%;    
    padding: 15px;
    margin: 0px;
}
.student-progress .badge ul li img {
    width: 300px;
    object-fit: contain;
}
.student-progress .my-table-wraper .my-table strong,
.student-progress .my-table-wraper .my-table form,
.student-progress .my-table-wraper .MuiPaper-rounded {
    padding: 0px;
} 
.student-progress table tbody tr td .lesson-icon {
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.student-progress .my-table-wraper tbody tr .stud-progress {
    font-weight: 600;
    background-color: #e6f4f8;
}
/*------------- 03.01.23 [END] -------------*/


.student-progress .piechart .statistics-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    clear: both;
}
.student-progress .piechart .statistics-wrap .box-wrap:first-child {
    border-right: 1px solid rgba(35, 33, 91, 0.1);
}
.student-progress .box-wrap {
    width: 50%;
    color: #23215B;
    padding: 1rem;
    font-size: 24px;
    font-weight: 600;
    display: inline-block;
}
.student-progress .box-wrap .img-box {
    padding: 4.5rem 0;
    text-align: center;
    position: relative;
}
.student-progress .box-wrap .img-box img {
    width: 250px;
}
.student-progress .box-wrap .img-box h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    transform: translate(-50%, -50%);
}
.student-progress .box-wrap label {
    color: #0092B8;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: inline-block;
}
.student-progress table tbody tr .more-link {
    color: #FFF;
    padding: 0.7rem 0.5rem;
    border-radius: 6px;
    background-color: #0092b8;
    cursor: pointer;
    transition: all 300ms linear;
}
.student-progress table tbody tr .more-link + .more-link {
    margin-top: 0.6rem;
    background-color: #e74c25;
}
.student-progress table tbody tr .more-link:hover {
    background-color: #006681;
}
.student-progress table tbody tr .more-link + .more-link:hover {
    background-color: #a2351a;
}


/*------------- 10.01.23 [START] -------------*/
.verification-wrap {
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
}
.verification-wrap .box-wrap {
    width: 100%;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
    background-color: #FFFFFF;
    position: relative;
}
.verification-wrap .img-wrap img {
    width: 300px;
    margin-bottom: 2rem;
}
.verification-wrap .box-wrap p a {
    color: #23215B;
    text-decoration: underline;
    font-weight: 600;
}
.verification-wrap .box-wrap p a:hover {
    color: #0092B8;
}
.verification-wrap:before {
    content: '';
    position: absolute;
    top: 100px;
    left: 100px;
    background: url(./assets/images//shape2.png)no-repeat center / contain;
    width: 30px;
    height: 30px;
    text-align: center;
    animation: rotation 8s infinite linear;
    transform: translateZ(0);
}
.verification-wrap:after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 40px;
    background: url(./assets/images//shape5.png)no-repeat center / contain;
    width: 90px;
    height: 90px;
    text-align: center;
    animation: mover 1s infinite alternate;
    transform: translateZ(0);
}
.verification-wrap .box-wrap:before {
    content: '';
    position: absolute;
    top: 90px;
    right: 0px;
    background: url(./assets/images//shape4.png)no-repeat center / contain;
    width: 50px;
    height: 50px;
    text-align: center;
    animation: mover 1s infinite alternate;
    transform: translateZ(0);
}
.verification-wrap .box-wrap:after {
    content: '';
    position: absolute;
    bottom: 90px;
    left: 0px;
    background: url(./assets/images//shape3.png)no-repeat center / contain;
    width: 50px;
    height: 50px;
    text-align: center;
    animation: mover 1s infinite alternate;
    transform: translateZ(0);
}
/*------------- 10.01.23 [END] -------------*/

/*------------- 11.01.23 [START] -------------*/
.certificate-wrap {
    padding-top: 70px;
    position: relative;
}
.certificate-wrap .container > .flex-wrap {
    margin: 0px;
    border: 2px solid #CED4DA;
    border-radius: 15px;
}
.certificate-wrap .flex-wrap > .left-area {
    border-right: 2px solid #CED4DA;
}
.certificate-wrap .flex-wrap .frm-content,
.certificate-wrap .flex-wrap #userCertificate {
    padding: 1rem;
    position: relative;
}
.certificate-wrap #userCertificate .signature {
    display: inline-block;
}
.certificate-wrap .custom-accordion-data-picker-wrap > form {
    width: 100%;
}
.certificate-wrap .custom-accordion-data-picker-wrap > form .MuiInputBase-input {
    font-family: 'Quicksand', sans-serif;
}
.certificate-wrap .custom-accordion-data-picker-wrap > form .MuiOutlinedInput-notchedOutline {
    border-color: #CED4DA;
    border-radius: 15px;
}
.certificate-wrap form canvas {
    width: 100% !important;
    height: 150px !important;
    border: 1px solid #CED4DA;
    border-radius: 15px;
}
.certificate-wrap .frm-content .sig-wrap {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.certificate-wrap .frm-content .clear {
    display: inline-block;
    cursor: pointer;
    opacity: 0.7;
}
.certificate-wrap .frm-content .clear img {
    width: 25px;
}
.certificate-wrap .frm-content label.text-danger {
    font-size: 15px;
    margin: 1rem 0;
}
.certificate-wrap #userCertificate .img-wrap {
    width: 100%;    
    text-align: center;
}
.certificate-wrap #userCertificate .img-wrap img {
    width: 930px;
}
.certificate-wrap #userCertificate .content-info {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: 2px dashed #FAAF11;
    border-radius: 6px;
    display: inline-block;
    position: relative;
}
/* .certificate-wrap #userCertificate .content-info::after {
    content: '';
    width: 100%;
    height: 80px;
    padding: 0.5rem 1rem;
    border: 2px dashed #FAAF11;
    border-radius: 6px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;    
    z-index: -9;
} */
.certificate-wrap #userCertificate .react-draggable {
    width: auto;
    height: 34px;
    margin-right: 3rem;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: normal;
    cursor: move;
    display: inline-block;
    position: relative;
}
.certificate-wrap #userCertificate .content-info .react-draggable:first-child {
    width: 50%;
}
.certificate-wrap #userCertificate .content-info .react-draggable:nth-child(2) {
    width: 15%;
}
.certificate-wrap #userCertificate .content-info .signature {
    width: 15%;
    height: 60px;
}
.certificate-wrap .assign-btn button{
    margin: 2rem auto 0;
}
/*------------- 11.01.23 [END] -------------*/

/*------------- 13.01.23 [START] -------------*/
.certificate .certificate-wrapper .img-box {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 15px;
    border: 1px solid #212529;
    overflow: hidden;
    display: inline-block;
    position: relative;
    clear: both;
    cursor: pointer;
}
.certificate .certificate-wrapper .img-box .box {
    width: 100%;
    height: 200px;
    position: relative;
}
.certificate .certificate-wrapper .img-box .box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.certificate .certificate-wrapper .img-box h4 {
    color: #212529;
    margin-bottom: 0px;
    padding: 0.5rem 1rem;
    font-size: 20px;
    text-align: center;
    line-height: normal;
    transition: all 300ms linear;
}
.certificate .certificate-wrapper .img-box:hover,
.certificate .certificate-wrapper .img-box:focus {
    border: 1px solid #0092B8;
}
.certificate .certificate-wrapper .img-box:hover h4 {
    color: #0092B8;
}
/*------------- 13.01.23 [END] -------------*/

/*------------- 27.01.23 [START] -------------*/
.my-table-wraper .common-table table tbody tr td.disable-link {
    color: rgba(0, 0, 0, 0.60);
    background-color: #f0f1f2;
    pointer-events: none;
}
/*------------- 27.01.23 [END] -------------*/

.my-table-wraper table tbody .action-btns {
    display: inline-block;
}
.my-table-wraper table tbody .action-btns img {
    cursor: pointer;
}

/*------------- 01.02.23 [START] -------------*/
.account-sec-wrap,
.renew-success-wrap,
.unsubscribe-wrap,
.renew-failure-wrap {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 30px;
    position: relative;
}
.account-sec-wrap .account-expire {
    padding: 40px 0;
    position: relative;
}
.account-sec-wrap .account-expire a {
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 15px;
    border: 1px solid #212529;
    text-align: center;
    box-shadow: 0 5px 40px rgb(0 0 0 / 5%);
    transition: all 0.5s ease;
    display: inline-block;
}
.account-sec-wrap .account-expire a:hover {
    border: 1px solid #0092B8;
}
.account-sec-wrap .account-expire img {
    width: 141px;
}
.account-sec-wrap .account-expire h4 {
    color: #212529;
    margin: 0px;
    font-size: 28px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.5s ease;
}
.account-sec-wrap .account-expire a:hover h4 {
    color: #0092B8;
}
.renew-success-wrap .box,
.unsubscribe-wrap .box,
.renew-failure-wrap .box,
.daily-limit-wrap .box {
    width: 100%;
    color: #212529;
    padding: 50px 30px;
    font-family: Quicksand;
    font-size: 16px;
    box-shadow: 0px 0px 40px 5px rgb(0 0 0 / 5%);
    border-radius: 10px;
    background-color: #FFFFFF;
    position: relative;
}
.renew-success-wrap .img-wrap,
.unsubscribe-wrap .img-wrap,
.renew-failure-wrap .img-wrap,
.daily-limit-wrap .img-wrap {
    width: 100%;
    text-align: center;
}
.renew-success-wrap .img-wrap img,
.renew-failure-wrap .img-wrap img,
.daily-limit-wrap .img-wrap img {
    width: 120px;
}
.renew-success-wrap .box p,
.unsubscribe-wrap .box p,
.renew-failure-wrap .box p,
.daily-limit-wrap .box p {
    margin-top: 2rem;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 600;
}
.unsubscribe-wrap .img-wrap img {
    width: 160px;
}
/*------------- 01.02.23 [END] -------------*/

.progress-report-wrap .report-img-wrap {
    width: 100%;
    height: 100%;
}
.Toastify__toast-theme--dark {
    color: #23215B !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 15px 3px rgb(0,0,0,0.10) !important;
}
.Toastify__close-button {
    color: #a1acb8 !important;
}
#reactgooglegraph-3 svg text {
    font-family: Quicksand,sans-serif !important;
}

/*------------- 15.02.23 [START] -------------*/
.sign-up-form-wraper.box-loader .loader {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}
.sign-up-form-wraper.box-loader .sign-in-form-wraper {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.sign-up-form-wraper.box-loader .loader .spinner-border {
    color: #0092B8;
    --bs-spinner-width: 3rem;
    --bs-spinner-height: 3rem;
}
.swal-button--cancel:focus,
.swal-button--danger:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgb(0 146 184 / 40%);
}
/* .swal-overlay .renew-plan-alert .swal-button-container .swal-button {
    min-width: 93px;
} */
/*------------- 15.02.23 [END] -------------*/


/*------------- 21.02.23 [START] -------------*/
.common-lessions-inner ul.search-area li {
    padding: 0px;
}
.common-lessions-inner .search-area li > div {
    width: 100%;
}
.common-lessions-inner .search-area li a {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.common-lessions-inner .search-area li a .img-wrap {
    width: 100px;
    height: 100px;
    padding: 10px;
    /* background-color: #F8F8FF; */
}
.common-lessions-inner .search-area li a .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.common-lessions-inner .search-area li a .category-name {
    flex: 1 1;
    padding: 1rem;
}
.common-lessions-inner .search-area li a .category-name span {
    width: 100%;
    display: block;
}
.common-lessions-inner .search-area li a .category-name .display-category {
    font-weight: 600;
    margin-bottom: 0.3rem;
}
.common-lessions-inner .search-area li a .category-name .display-subCategory {
    font-size: 18px;
}
/*------------- 21.02.23 [END] -------------*/

/*------------- 22.02.23 [START] -------------*/
.worksheet-wrap {
    padding-bottom: 30px;
    padding-top: 70px;
    position: relative;
    width: 100%;
}
.worksheet-wrap .worksheet-box a {
    border: 1px solid #212529;
    border-radius: 15px;
    box-shadow: 0 5px 40px rgb(0 0 0 / 5%);
    display: inline-block;
    padding: 2rem 1rem;
    text-align: center;
    transition: all .5s ease;
    width: 100%;
}
.worksheet-wrap .worksheet-box h4 {
    color: #212529;
    font-family: Quicksand,sans-serif;
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    transition: all .5s ease;
}
.worksheet-wrap .worksheet-box img {
    width: 120px;
}
.worksheet-wrap .worksheet-box a:hover {
    border: 1px solid #0092b8;
}
.worksheet-wrap .worksheet-box a:hover h4 {
    color: #0092b8;
}
/*------------- 22.02.23 [END] -------------*/

/*------------- 23.02.23 [START] -------------*/
.upload-worksheet-wrap {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 3rem;
    border: 1px solid #ced4da;
    border-radius: 15px;
    background-color: #FFFFFF;
}
.upload-worksheet-wrap form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}
.upload-worksheet-wrap .drop-down-menu {
    width: 20%;
}
.upload-worksheet-wrap .add-icn-wraper {
    width: 51px;
    margin: 0 auto;
}
.upload-worksheet-wrap form .yellow-button {
    font-size: 16px;
    height: 48px;
    padding: 10px 25px;
}
.custom-accordion-data-picker-wrap .MuiTextField-root label {
    color: #212529;
    text-transform: capitalize;
    font-family: 'Quicksand', sans-serif;
}
.sign-in-form-wraper .common-form .input-group-text span.MuiTouchRipple-root,
.modal .modal-body .input-group-text span.MuiTouchRipple-root {
    width: 100%;
}
/*------------- 23.02.23 [END] -------------*/

.questions-wrap-box .CountdownCircleTimer {
    width: 100%;
    color: #3e98c7;
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}
.questions-wrap-box .CountdownCircleTimer > div,
.questions-wrap-box .CountdownCircleTimer svg {
   width: 110px !important;
   height: 110px !important;
}
.common-lessions-inner .common-lessions-inner-repeat ul li div:not(.tooltip-links) {
    padding-right: 8px;
}
.common-lessions-inner .common-lessions-inner-repeat ul li div:not(.tooltip-links) a {
    word-break: break-word;
}





/*  responsive start from here */
@media(min-width:320px) {
    .news-calender ol li { width: calc(100% / 2); }
    .news-calender ol li:nth-last-child(-n+4) { border-bottom: 0px; }
}
@media(min-width:768px) and (max-width:1199px) {
    .news-calender ol li { width: calc(100% / 4); }
    .news-calender ol li:nth-of-type(4n) { border-right: 0px; }
    .news-calender ol li:nth-last-child(-n+4) { border-bottom: 0px; }
}
@media(min-width:1200px) {
    .news-calender ol li { width: calc(100% / 6); }
    .news-calender ol li:nth-of-type(6n) { border-right: 0px; }
    .news-calender ol li:nth-last-child(-n+6) { border-bottom: 0px; }
}
@media(max-width:1440px) {
    .common-pop .modal-dialog { margin: 0 auto; max-width: 1200px; padding: 20px 15px; width: 100%; }
}
@media(max-width:1440px) {
    .container { max-width: 1120px; }
    h1 { font-size: 46px; line-height: 55px; }
    h2 { font-size: 36px; }
    h4 { font-size: 30px; }
    .common-section-content span { font-size: 24px; line-height: 34px; }
    .creating_class_rosters_wrap span { font-size: 28px; line-height: 38px; }
    .common-section-content span { font-size: 22px; line-height: 30px; }
    .creating_class_rosters_wrap span { font-size: 28px; line-height: 38px; }
    ul.hero-menu li img { max-width: 80px; max-height: 100px; }
    ul.hero-menu li { height: 134px; }
    .common-form { padding-left: 15px; }
    .form-group.half-fuied-main, .form-group.third-fuied-main { margin: 0px -10px; }
    .form-group.half-fuied-main > div { margin: 0px 10px; width: calc(50% - 20px); }
    .sign-in-image { width: 40%; }
    .common-form { width: calc(60% - 0px); }
    .sign-up-form-wraper span.error { font-size: 18px; line-height: 28px; }
    .pop-monthly-reminder { font-size: 24px; width: 500px; }
    .assign-image { width: calc(50% - 30px); margin: 0px 15px; }
    .assign-contne { width: calc(50% - 30px); margin: 0px 15px; }
    .assign-lessons-wraper { margin: 0px -15px; }
    .assign-contne ul li a { font-size: 26px; line-height: 36px; }
    ul.navbar-nav li a.nav-link { font-size: 20px; }
    .yellow-button { height: auto; font-size: 20px; }
    .signup-wrap .section-title h5 { font-size: 24px; line-height: 38px; }
    .signup-wrap .account-type a img,
    .account-sec-wrap .account-expire img { width: 100px; }
    .signup-wrap .account-type h4,
    .account-sec-wrap .account-expire h4 { font-size: 24px; margin: 0.5rem 0; }
    .signup-wrap .account-type p { font-size: 20px; }

    .worksheet-wrap .worksheet-box img {
        width: 80px;
    }
    .worksheet-wrap .worksheet-box h4 {
        font-size: 20px;
    }
}
@media (max-width:1280px) {
    ul.hero-menu li img { width: 60px; height: 90px; object-fit: contain; }
    ul.hero-menu li { height: 140px; }
    h1 { font-size: 40px; line-height: 50px; }
    h2 { font-size: 34px; line-height: 40px; }
    body h3 { font-size: 30px; line-height: 40px; }
    h4 { font-size: 28px; line-height: 38px; }
    .yellow-button { font-size: 20px; }
    .sign-up-form-wraper span.error { margin-top: 20px; font-size: 20px; }
    .common-form { padding-left: 15px; }
    .sign-up-form-wraper .sign-in-form-wraper, 
    .renew-wrap .form-wrap,
    .progress-report-wrap .box-wrap { padding: 15px; }
    .form-group.half-fuied-main > div { margin: 0px 15px; width: calc(50% - 30px); }
    .form-group.half-fuied-main, .form-group.third-fuied-main { margin-left: -15px; margin-right: -15px; }
    .common-banner-wraper { min-height: 450px; }
    select.form-select, input.form-control, .renew-wrap .total-cost .price { height: 45px; }
    ul.contact-card-wrap li { padding: 15px; }
    ul.contact-card-wrap li a { font-size: 15px; }

    .sub-category .card .img-wrap {
        width: 437px;
        height: 180px;
    }
    .sub-category .card-body .card-title {
        font-size: 20px;
    }
    .student-assignments-wrap .level-category .card .img-wrap {
        width: 337px;
    }
    .student-assignments-wrap .level-category .card-body .card-title,
    .student-assignments-wrap .assignments-list .card-title {
        font-size: 16px;
    }
    .my-setting-inner .frm-details > label,
    .my-setting-inner .frm-details .custom-radio span {
        font-size: 16px;
    }
    .my-setting-form .my-setting-buttons button {
        font-size: 18px;
    }
    /* .common-in-progress .CircularProgressbar {
        width: 58%;
    } */
    .common-in-progress video { 
        width: 60%; 
    }    
    .award-sec-wrap .common-accordion-wrap .my-table-wraper,
    .award-sec-wrap .common-accordion-wrap .common-accordion-wraper-table {
        max-width: 950px;
    }


    /*-------------- 23.12.22 [START] --------------*/
    span.common-header-animation:before {
        width: 50px;
        height: 55px;
    }
    span.common-header-animation:after {
        width: 69px;
        height: 50px;
    }
    span.common-header-animation-two:before {
        width: 74px;
        height: 62px;
    }
    span.common-header-animation-two:after {
        width: 55px;
        height: 55px;
    }    
    .blog-main .latest-blog-wrap ul li {
        margin-bottom: 2rem;
    }
    /*-------------- 23.12.22 [END] --------------*/

    .per-month-tickets span {
        font-size: 14px;
    }
    .per-month-tickets strong {
        font-size: 22px;
    }
    .upload-pdf-wrap .add-icn input.form-control {
        height: 56px;
    }
}

@media(max-width:1190px) {
    body { font-size: 18px; line-height: 28px; }
    h1 { font-size: 40px; line-height: 48px; }
    h2 { font-size: 34px; line-height: 40px; }
    h4 { font-size: 28px; line-height: 38px; }
    .yellow-button { width: auto; padding: 10px 25px; max-width: max-content; font-size: 18px; height: auto; }
    .creating_class_rosters_wrap span { font-size: 28px; line-height: 38px; }
    .common-section-content span { font-size: 20px; line-height: 30px; }
    ul.hero-menu li { height: 105px; }
    .latest-blog-wrap li { padding: 15px; }
    ul.navbar-nav li { margin: 5px 0px; }
    ul.hero-menu { margin-top: -52px; }
    ul.pagination.pagination.justify-content-center { padding-bottom: 70px; }
    ul.bread-cum li { font-size: 22px; }
    ul.bread-cum li:before { width: 10px; height: 10px; border-width: 3px; }
    .common-form { width: calc(100% - 450px); }
    .sign-in-image { width: 450px; }
    .pop-monthly-reminder { font-size: 24px; max-width: 452px; line-height: 30px; }
    .monthly-content { width: calc(50% - 30px); }
    .monthly-image { width: calc(50% - 30px); }
    .monthly-content strong { font-size: 24px; }
    .common-animation-inner { padding: 60px  0px; }
    .assign-image { width: calc(50% - 50px); }
    .assign-contne { width: calc(50% - 50px); }
    .assign-contne ul li a { font-size: 24px; line-height: 32px; }
    .assign-contne ul li { margin-bottom: 20px; }
    .membership-order-main { padding: 60px 0px; }
    .upcomingWebinars-main { padding: 60px 0px; }
    .table-of-content { padding: 60px 0px; }
    .pages-name-details { padding: 60px 0px; }
    .mychildren { padding: 60px 0px; }
    .teacher-training { padding: 60px 0px; }
}
@media(max-width:991px) {
    h1 { font-size: 36px; line-height: 42px; }
    h2, .terms-details .terms-info h2 { font-size: 30px; line-height: 40px; }
    h4 { font-size: 24px; line-height: 32px; }
    .creating_class_rosters_wrap span { font-size: 22px; line-height: 30px; }
    .header-button ul.header-buttons { margin: 10px -15px 0px -15px; justify-content: center; }
    ul.hero-menu li img { width: 45px; height: 60px; object-fit: contain; }
    ul.hero-menu li { height: 80px; margin: 0px 10px; width: calc(14.28% - 20px); }
    ul.hero-menu { margin: 0px -10px; margin-top: -60px; }
    section.common-section { padding: 40px 0px 0px 0px; }
    .terms-details { padding: 70px 0px; }
    .common-banner-wraper { min-height: 350px; }
    .common-form { width: calc(60%); }
    .sign-in-image { width: calc(40%); }
    .common-pop .modal-body { padding: 20px; }
    .common-pop .modal-header { padding: 14px 20px; }
    .blog-details-main { padding: 60px 0px 60px; }
    .blog-post-details strong { font-size: 24px; }
    .momthly-contest-prize { padding-top: 60px; }
    .monthl-reminder { padding: 60px 0px 0px; }
    .contest-winners { padding: 60px 0px 0px; }
    ul.contact-card-wrap li { width: calc(50% - 20px); margin: 10px; flex: 1; }
    .contact-us .sign-up-form-wraper:after { width: 60px; height: 60px; }
    ul.per-month > li { width: 100%; max-width: 500px; padding: 0px; margin: 0 auto 1rem; }
    /* ul.per-month > li:first-child .per-month-card { border-radius: 10px 10px 0px 0px; }
    ul.per-month > li:first-child li:last-child { border-radius: 0px; }
    ul.per-month > li:last-child .per-month-card { border-radius: 0px; }
    ul.per-month > li:last-child li:last-child { border-radius: 0px 0px 20px 20px; } */
    .per-month-tickets strong { font-size: 22px; }
    .per-month-tickets span { font-size: 16px; }
    .add-child-button em { display: block; margin-bottom: 10px; }
    .mychildren { padding-bottom: 40px; }
    .tab-items .MuiTabs-scroller { overflow: auto !important; }
    .child-wraper { padding: 60px  0px; }
    .signup-wrap .account-type .col-lg-3:first-child,
    .account-sec-wrap .account-expire .col-lg-3:first-child,
    .worksheet-wrap .worksheet-box .col-lg-3:first-child { margin-bottom: 2rem; }
    .common-pop-footer { left: -20px; right: -20px; }

    /*-------------- 24.12.22 [START] --------------*/
    .contact-sec-wrap .sign-in-image,
    .sign-in-form-wraper .sign-in-image {
        display: none;
    }
    .contact-sec-wrap .common-form,
    .sign-in-form-wraper .common-form {
        width: calc(100%);
    }
    /*-------------- 24.12.22 [END] --------------*/
}
@media(min-width:768px) and (max-width:1023px) {
    .hero-banner-wrap {height: 500px;}
    .sub-category .card-body .card-title,
    .student-assignments-wrap .level-category .card-body .card-title {
        font-size: 16px;
    }
    .sub-category .card .img-wrap,
    .student-assignments-wrap .level-category .card .img-wrap {
        width: 40%;
        height: 160px;
        background-color: #F8F8FF;
    }
    .sub-category:last-child,
    .student-assignments-wrap .level-category:last-child {
        margin-bottom: 3rem;
    }
    .student-assignments-wrap .assignments-list {
        width: 100%;
        margin-bottom: 1.5rem;
        display: inline-block;
    }
    .student-assignments-wrap .assignments-list .img-wrap {
        height: 150px;
    }
    .my-setting-inner .frm-details {
        flex-direction: column;
    }
    .my-setting-inner .frm-details > label {
        width: 100%;
        margin-bottom: 1rem;
    }
    .my-setting-inner .frm-details .custom-radio {
        width: 100%;
    }
    .assignment-detail-wrap .assigment-info ul li {
        width: 100%;
    }
    .header-top form {
        margin: 0 auto;
    }
    .header-top-main ul.header-top-buttons {
        width: 100%;
        margin-top: 1rem;
        justify-content: center;
    }
    .changeuser-pop-main  .changes-user-pop {
        width: calc(33.33% - 30px);
    }

    /*------------- 27.12.22 [START] -------------*/
    .child-wraper .my-table-wraper table tbody tr td:first-child,
    .account-tab .my-table-wraper table tbody tr td:first-child {
        padding: 10px;
    }
    .child-wraper .my-table-wraper table tbody tr td:first-child .lesson-icon
    .account-tab .my-table-wraper table tbody tr td:first-child .lesson-icon {
        height: auto;
        object-fit: contain;
    }
    /*------------- 27.12.22 [END] -------------*/

    /*------------- 28.12.22 [START] -------------*/
    .common-section:before, .common-section:after { content: none; }
    section.creating_class_rosters:before, section.creating_class_rosters:after { content: none; }
    .award-wrapper .awards-list .img-wrap {
        height: 180px;
    }
    /*------------- 28.12.22 [END] -------------*/

    /*------------- 29.12.22 [START] -------------*/
    .lesson-report-wrap .lesson-info ul li,
    .progress-report-wrap ul li {
        width: 50%;
    }
    /*------------- 29.12.22 [END] -------------*/

    /*------------- 12.01.22 [START] -------------*/
    .certificate-wrap .flex-wrap .col-lg-3 {
        border-bottom: 2px solid #CED4DA;
        border-right: none;
    }
    /*------------- 12.01.22 [END] -------------*/

    .progress-report-wrap .report-img-wrap {
        width: 100%;
        height: 300px;
        margin-bottom: 1rem;
    }
    .about-page-wraper .about-common-image .common-section-image {height: 250px;}
    .upload-worksheet-wrap .drop-down-menu {
        width: 30%;
    }
}

@media(max-width:767px) {
    h1 { font-size: 24px; line-height: 32px; }
    h2, .terms-details .terms-info h2 { font-size: 26px; line-height: 32px; }
    body h3 { font-size: 22px; line-height: 30px; }
    .hero-banner-wrap {height: 350px;}
    ul.hero-menu li { width: calc(50% - 20px); margin: 10px; height: 120px; padding: 10px; text-align: center; display: block; }
    ul.hero-menu li img { height: 100%; width: 100%; object-fit: contain; }
    ul.hero-menu { margin-top: -72px; }
    ul.bread-cum li { font-size: 13px; margin: 0 10px; }
    ul.bread-cum li:before { width: 7px; height: 7px; border-width: 2px; right: -10px; }
    .common-section-content { width: calc(100% - 30px); margin: 0px 15px; }
    .common-section-image { width: calc(100% - 30px); margin: 15px; }
    .about-page-wraper .about-common-image .common-section-image {height: 220px;}
    .common-section:before, .common-section:after { content: none; }
    section.creating_class_rosters:before, section.creating_class_rosters:after { content: none; }     
    .blog-main { padding: 40px 0px; }
    ul.pagination.justify-content-center { padding-bottom: 40px; }
    ul.pagination li a { width: 30px; height: 30px; font-size: 16px; }
    ul.pagination li { margin: 0px 5px; }
    section.common-banner > span:before { max-width: 50px; }
    section.common-banner > span:after { max-width: 44px; }
    .news-table-main { padding-top: 40px; }
    .news-years { max-width: 120px; font-size: 20px; }
    .news-calender ol li:empty { display: none }
    .news-calender ol li { font-size: 14px; padding: 5px 15px; }
    .news-calender ol li:nth-child(even) { border-right: 0px; }
    .common-banner-wraper { min-height: 250px; }
    span.common-header-animation:before { left: 15px; top: 20px; }
    span.common-header-animation:after { left: 15px; bottom: 20px; }
    section.common-banner > span:before { right: 15px; bottom: 20px; }
    section.common-banner > span:after { bottom: 15px; right: 20px; }
    .MuiAccordionSummary-content p { font-size: 18px; }
    .MuiCollapse-vertical p { font-size: 14px; }
    .common-form { width: calc(100%); padding: 0px; }
    .sign-in-image { width: calc(100%); margin-bottom: 20px; }
    .sign-in-image img { max-height: 300px; }
    .sign-up-form-wraper { padding: 40px 0px; }
    .form-group.half-fuied-main > div, .form-group.third-fuied-main > div { margin: 0px 15px; width: 100%; }
    .sign-in-form-wraper .form-group.half-fuied-main>div {margin-bottom: 20px;}
    .sign-up-form-wraper span.error { margin-top: 15px; font-size: 16px; line-height: 22px; }
    .modal { margin: 0px 0px; padding: 20px 0px; }
    .common-pop .modal-title.h4 { font-size: 22px; }
    .blog-details-main { padding: 40px 0px 40px; }
    .blog-commnets ul li .blog-commnet-image { width: 40px; height: 40px; }
    .blog-commnets ul li .blog-commnet-image { width: 40px; height: 40px; }
    .blog-commnets ul li:nth-child(even) { padding-left: 20px; }
    .blog-commnets ul li:nth-child(even) { padding-left: 20px; }
    .blog-post-comment { margin-left: 15px; width: calc(100% - 55px); }
    .blog-image { padding: 5px; }
    .blog-post-details { padding: 20px 0px 0px; }
    .blog-post-comment { margin-left: 15px; width: calc(100% - 55px); }
    .blog-image { padding: 10px; }
    .solve span { margin: 0px; height: 45px; }
    .blog-form button.yellow-button { margin: 20px 15px 0px; }
    .blog-post-details strong { font-size: 22px; }
    .terms-details { padding: 40px 0px; }
    .roman-numeral-counter li { padding: 12px 15px 12px 65px; }
    .roman-numeral-counter li:before { left: 18px; }
    .roman-numeral-counter li > span { font-size: 16px; }
    ul.roman-numeral-counter.inner-counter li { padding: 0px; }
    .roman-numeral-counter.inner-counter li:before { top: 0px; }
    .type-of-accout-section-repeat { padding-left: 20px; }
    .monthly-image { width: calc(100% - 30px); margin-bottom: 20px; }
    .monthly-content { width: calc(100% - 30px); }
    .monthl-reminder { padding: 40px 0px 0px; }
    .monthly-content strong { font-size: 22px; }
    .pop-monthly-reminder { padding: 15px; font-size: 22px; }
    .monthly-form button.yellow-button { margin: 20px 15px; }
    .monthly-form form { margin-bottom: 40px; }
    .contest-winners { padding: 40px 0px 40px 0px; }
    .contest-winners table thead td { font-size: 18px; padding: 10px 15px; }
    .contest-winners table tbody td { font-size: 16px; padding: 10px 15px; }
    ul.contact-card-wrap li { width: calc(100% - 20px); flex: auto; }
    .contact-us .sign-up-form-wraper:after { right: 15px; }
    .student-project { padding: 40px 0px 0px; }
    .main-title { margin-bottom: 20px; }
    .about-page-wraper { padding: 0px; }
    .my-table strong { display: block; width: 100%; }
    .my-table { flex-wrap: wrap; }
    .my-table form { min-width: auto; margin-bottom: 0px; }
    .email-setting .email-setting-content { width: 100%; }
    .email-setting .switch { width: 100%; }
    .email-setting .switch span.MuiSwitch-sizeMedium { margin-left: 0px; margin-right: auto; }
    .email-setting { padding: 10px 0px; }
    .assignments-main .child-wraper > strong { font-size: 20px; }
    .assign-image { width: calc(100% - 50px); margin: 25px; }
    .assign-contne { width: calc(100% - 50px); }
    .renewal-information > div > a { font-size: 20px; }
    .assign-contne ul li a { font-size: 20px; line-height: 24px; }
    .assign-contne ul li a:after { content: ''; width: 10px; height: 10px; }
    .renewal-information-wrap strong { font-size: 22px; }
    .renewal-information-wrap em { font-size: 18px; }
    .my-table strong { font-size: 20px; }
    .my-table { padding: 10px 15px; }
    .child-wraper { padding: 40px 0px 0px; }
    .common-accordion-main > .container strong { font-size: 20px; line-height: 28px; }
    .membership-order-main { padding: 40px 0px; }
    .upcomingWebinars-main { padding: 40px 0px; }
    .table-of-content { padding: 40px 0px; }
    .pages-name-details { padding: 40px 0px; }
    .pop-monthly-reminder { width: 100%; }
    .mychildren { padding: 40px 0px; }
    .faq-wraper .MuiAccordionSummary-content { padding: 15px 40px 15px 15px; }
    .teacher-training { padding: 40px 0px }
    .teacher-traing-button > div { width: calc(100% - 30px); margin: 0px 15px; }


    .sub-category .card,
    .student-assignments-wrap .level-category .card {
        flex-direction: column;
    }
    .student-assignments-wrap .assignments-list {
        width: 100%;
        margin-bottom: 1.5rem;
        display: inline-block;
    }
    .sub-category .card-body,
    .level-category .card-body {
        width: 100%;
    }
    .sub-category .card-body .card-title,
    .student-assignments-wrap .level-category .card-body .card-title,
    .student-assignments-wrap .assignments-list .card-title {
        font-size: 16px;
    }
    .student-assignments-wrap .assignments-list .img-wrap {
        height: 150px;
    }
    .sub-category .card .img-wrap,
    .student-assignments-wrap .level-category .card .img-wrap {
        width: 100%;
        height: 160px;
        background-color: #F8F8FF;
    }
    .student-assignments-wrap .level-category .card .img-wrap {
        border-right: 0px;
        border-bottom: 1px solid #CED4DA;
    }
    .student-assignments-wrap .level-category .card:hover .img-wrap {
        border-right: 0px;
        border-bottom: 1px solid #0092B8;
    }
    .sub-category:last-child,
    .level-category:last-child {
        margin-bottom: 3rem;
    }
    .sub-category:nth-child(odd) .border-primary .img-wrap {
        border-right: 0px;
        border-bottom: 2px solid #FBBF02;
    }
    .sub-category:nth-child(even) .border-primary .img-wrap {
        border-right: 0px;
        border-bottom: 2px solid #13A89F;
    }
    .renew-wrap .renew-button a + a {
        margin-left: auto;
    }

    .my-setting-inner {
        padding: 10px;
    }
    .my-setting-inner .frm-details {
        flex-direction: column;
    }
    .my-setting-inner .frm-details > label {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
    }
    .my-setting-inner .frm-details .custom-radio {
        justify-content: center;
    }
    .my-setting-inner .animation-wrap .custom-radio label {
        width: 40%;
        margin-bottom: 15px;
    }
    .my-setting-form .my-setting-buttons {
        justify-content: space-between;
    }
    .my-setting-form .my-setting-buttons button {
        font-size: 15px;
        padding: 10px 26px;
    }
    .assignment-detail-wrap .assigment-info .content {
        margin-top: 1.5rem;
    }
    .assignment-detail-wrap .assigment-info ul li {
        width: 100%;
    }    
    .assignment-detail-wrap .assigment-button label {
        width: 100%;
        margin-bottom: 1rem;
    }

    /*-------------- 23.12.22 [START] --------------*/
    .about-main-page .student-project:first-child::after,
    .about-main-page .student-project:first-child:before,
    .about-main-page .student-project:nth-child(2)::after,
    .about-main-page .student-project:nth-child(3):before,
    .about-main-page .student-project:nth-child(4):before,
    .stars:before,
    .stars:after,
    .faq-main-section:after,
    .faq-main-section:before,
    .common-animation:before,
    .common-animation-inner:before,
    .common-animation-inner:after {
        content: none;
    }

    .blog-main::after,
    .blog-main::before,
    .blog-main .latest-blog-wrap::before,
    .blog-main .latest-blog-wrap::after,
    .blog-details-main::before,
    .blog-details-main::after,
    .blog-details-wrap:before,
    .blog-details-wrap:after {
        content: none;
    }
    /*-------------- 23.12.22 [END] --------------*/

    /*-------------- 24.12.22 [START] --------------*/
    .contact-us .sign-up-form-wraper .sign-in-form-wraper:before,
    .contact-us .sign-up-form-wraper .sign-in-form-wraper:after {
        content: none;
    }
    /*-------------- 24.12.22 [END] --------------*/

    /*-------------- 25.12.22 [START] --------------*/
    .account-tab .my-table strong,
    .upcomingWebinars-main .my-table strong,
    .mychildren .my-table strong,
    .child-wraper .my-table strong,
    .assignment-detail-wrap .my-table strong,
    .progress-report-wrap .my-table strong,
    .student-progress .my-table strong {
        width: auto;
    }
    .progress-report-wrap .report-img-wrap {
        width: 100%;
        height: 200px;
        margin-bottom: 1rem;
    }
    .changeuser-pop-main  .changes-user-pop {
        width: calc(50% - 30px);
    }
    /*-------------- 25.12.22 [END] --------------*/

    /*------------- 27.12.22 [START] -------------*/
    .child-wraper .my-table-wraper table tbody tr td:first-child,
    .account-tab .my-table-wraper table tbody tr td:first-child {
        padding: 10px;
    }
    .child-wraper .my-table-wraper table tbody tr td:first-child .lesson-icon
    .account-tab .my-table-wraper table tbody tr td:first-child .lesson-icon {
        height: auto;
        object-fit: contain;
    }
    .award-wrapper .awards-list .img-wrap {
        height: 150px;
    }
    /*------------- 27.12.22 [END] -------------*/

    /*------------- 29.12.22 [START] -------------*/
    .lesson-report-wrap .lesson-info ul li,
    .progress-report-wrap ul li {
        width: 100%;
    }
    /*------------- 29.12.22 [END] -------------*/

    /*------------- 30.12.22 [START] -------------*/
    .progress-wrap .main-content .chart-info {
        padding: 15px;
    }
    .progress-wrap .chart-info .question-info ul li {
        width: 100%;
    }
    .progress-wrap .chart-info .chart-data-wrap .piechart,
    .progress-wrap .chart-info .chart-data-wrap .barchart {
        width: 100%;
    }
    .badge-img-wrap .img-box {
        height: 300px;
    }
    /*------------- 30.12.22 [END] -------------*/

    /*------------- 10.01.22 [START] -------------*/
    .verification-wrap .box-wrap {
        padding: 10px;
    }
    .verification-wrap:before,
    .verification-wrap:after,
    .verification-wrap .box-wrap:before,
    .verification-wrap .box-wrap:after {
        content: none;
    }
    .verification-wrap .img-wrap img {
        width: 200px;
    }
    /*------------- 10.01.22 [END] -------------*/

    /*------------- 12.01.22 [START] -------------*/
    .certificate-wrap .flex-wrap .col-lg-3 {
        border-bottom: 2px solid #CED4DA;
        border-right: none;
    }
    /*------------- 12.01.22 [END] -------------*/

    .student-progress .box-wrap {
        width: 100%;
    }
    .student-progress .piechart .statistics-wrap .box-wrap:first-child {
        border-right: 0px;
        border-bottom: 1px solid rgba(35, 33, 91, 0.1);
    }
    .student-progress .box-wrap .img-box img {
        width: 200px;
    }
    .student-progress .box-wrap .img-box h5 {
        font-size: 40px;
    }
    .renew-success-wrap .img-wrap img,
    .unsubscribe-wrap .img-wrap img,
    .renew-failure-wrap .img-wrap img,
    .daily-limit-wrap .img-wrap img {
        width: 80px;
    }

    .upload-worksheet-wrap form {
        flex-direction: column;
    }
    .upload-worksheet-wrap .drop-down-menu,
    .upload-worksheet-wrap .upload-pdf-wrap {
        width: 50%;
    }
    .upload-worksheet-wrap .add-icn {
        width: 100%;
        margin: 1rem 0 0;
        text-align: left;
    }
    .upload-worksheet-wrap .add-icn-wraper {
        margin: 0px;
    }
    .upload-worksheet-wrap form .yellow-button {
        margin-top: 2rem;
    }
    .common-lessions-inner .common-lessions-inner-repeat ul li div:not(.tooltip-links) {
        flex: 1;
        padding-right: 8px;
    }
    .common-lessions-inner .tooltip-links a {
        font-size: 12px;
        width: 30px;
        height: 30px;
    }
    .common-lessions-inner .tooltip-links a span {
        width: 12px;
        height: 6px;
    }
}

@media(max-width:480px) {
    /*-------------- 25.12.22 [START] --------------*/
    .account-tab .my-table,
    .upcomingWebinars-main .my-table,
    .mychildren .my-table,
    .child-wraper .my-table,
    .assignment-detail-wrap .my-table,
    .progress-report-wrap .my-table,
    .student-progress .my-table  {
        justify-content: center;
    }
    .account-tab .my-table strong,
    .upcomingWebinars-main .my-table strong,
    .mychildren .my-table strong,
    .child-wraper .my-table strong,
    .assignment-detail-wrap .my-table strong,
    .progress-report-wrap .my-table strong,
    .student-progress .my-table strong {
        margin-bottom: 0.5rem;
    }
    .account-tab .my-table form,
    .account-tab .my-table form > div,
    .upcomingWebinars-main .my-table form,
    .upcomingWebinars-main .my-table form > div,
    .mychildren .my-table form,
    .mychildren .my-table form > div,
    .child-wraper .my-table form,
    .child-wraper .my-table form > div {
        width: 100%;
    }
    .account-tab .my-table form input,
    .upcomingWebinars-main .my-table form input,
    .mychildren .my-table form input,
    .child-wraper .my-table form input {
        min-width: 100%;
    }
    .report-form .custom-accordion-data-picker-wrap {
        flex-direction: column;
    }
    .report-form .custom-accordion-data-picker-wrap > form {
        width: 100%;
    }
    .report-form .custom-accordion-data-picker-wrap > span {
        padding: 0.5rem 0;
    }
    .changeuser-pop-main  .changes-user-pop {
        width: calc(100% - 30px);
    }
    /*-------------- 25.12.22 [END] --------------*/

    .upload-worksheet-wrap .drop-down-menu,
    .upload-worksheet-wrap .upload-pdf-wrap {
        width: 100%;
    }
}