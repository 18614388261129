/* h1 {
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 40px;
  }
  
  .timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
  }
  
  .value {
    font-size: 40px;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }
   */

/*------------- 28.12.22 [START] -------------*/
.lessions-display { 
  width: calc(75% - 20px);
  margin: 0px 10px; 
  background: #FFFFFF; 
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); 
  border-radius: 10px; 
  position: relative;
}
.lession-header { 
  display: flex; 
  padding: 18px 20px; 
  background: #E6F4F8; 
  border-radius: 10px 10px 0px 0px; 
}
.lession-header h4 { 
  font-weight: 600; 
  font-size: 26px; 
  line-height: 35px; 
  color: #0092B8; 
  background: #E6F4F8; 
  margin: 0px; 
  font-family: 'Quicksand', sans-serif;
}
.lession-header h4 img { 
  margin-right: 14px; 
}
.questions-wrap-box {
  width: 100%;
  min-height: 672px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
}
.question-display img { 
  margin: 0 auto; 
  display: block; 
}
/*------------- 28.12.22 [END] -------------*/

/*------------- 30.12.22 [START] -------------*/
.questions-wrap-box .question-display {
  width: 100%; 
  height: calc(100% - 70px);
  display: flex; 
  flex-wrap: wrap;   
  justify-content: center;
}
.question-display .question-info {
  padding: 0.5rem;
  margin: 0 1rem;
  border: 2px dashed #FAAF11;
  border-radius: 6px;
  text-transform: capitalize;
  word-break: break-word;
  display: inline-block;
}
.question-display .img-box {
    width: 100%;
    height: 250px;
    position: relative;
}
.question-display .img-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.question-display .standard-fill-input .input-field,
.question-display .question-answer .input-field {
  width: 30%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.question-display .standard-fill-input .form-label,
.question-display .question-answer .form-label {
  margin-right: 1rem;
}
.question-display .multi-image-options .question-info {
  /* width: 100%; */
  margin: 0 0 1rem;
  height: 250px;
}
.question-display .multi-image-options .question-info img {
  height: 100%;
}
/* .question-display .multi-image-options .question-info img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */
.question-display .question-info:hover,
.question-display .question-info:focus,
.question-display .question-info.active {
  color: #FFFFFF;
  border-color: #198754;
  background-color: #198754;
}
.question-display .question-info:hover h5,
.question-display .question-info:focus h5,
.question-display .question-info.active h5 {
  color: #FFFFFF;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader img {
  max-width: 40px;
  max-height: 40px;
  margin-top : 90px;
}
/*------------- 30.12.22 [END] -------------*/

/*------------- 31.12.22 [START] -------------*/
.question-display label {
  margin-right: 30px;
  margin-bottom: 15px;
}
.question-display label:last-child {
  margin-right: 0px;
}
.question-display .MuiCheckbox-colorPrimary {
  color: #FAAF11;
}
.question-display .MuiCheckbox-colorPrimary svg {
  width: 2em;
  height: 2em;
}
.question-display label h4 {
  color: #212529;
  font-size: 28px;
  font-weight: 600;
  font-family: 'Quicksand', sans-serif;
}
.question-display .Mui-checked svg {
  fill: #198754;
}
.multi-image-options,
.image-and-options,
.standard-fill-input,
.multi-image-dragbox,
.select-checkbox-input,
.multi-question-drag-answer-image,
.question-image-multi-image,
.question-image-alpha-numerical-sequence,
.multi-answer-image-multi-checkbox,
.multi-question-image-answer-text-true-false,
.question-image-option-abcd,
.question-answer,
.question-sub-questions-answers,
.question-image-position-abcd {
  width: 100%;
  position: relative;
  clear: both;
}
.multi-image-dragbox > div > div {
  flex-wrap: wrap;
  min-height: auto;
}
.multi-image-dragbox .drag-box {
  width: 100%;
  min-width: 100%;
  min-height: 200px;
  height: auto;
  margin-right: 0px;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}
.multi-image-dragbox .drag-box:first-child {
  order: 2;
  border: 2px solid #FAAF11;
  margin: 0px;
}
.multi-image-dragbox .drag-box:nth-child(2) {
  border: 2px dashed #FAAF11;
}
.multi-image-dragbox .drag-box .secondary-details {
  width: 120px;
  height: 120px;
  padding: 0 15px;
  margin: 1rem 0;
  position: relative;
}
.multi-image-dragbox .drag-box .secondary-details img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.question-image-multi-image label .multi-img {
  width: 100%;
  height: 80px;
  position: relative;
}
.question-image-multi-image label .multi-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.multi-question-drag-answer-image .img-sec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.multi-question-drag-answer-image .img-sec img {
  width: 150px;
}
.multi-question-drag-answer-image .drag-box-sec > div > div {
  flex-wrap: wrap;
  min-height: auto;
}
.multi-question-drag-answer-image .drag-box {
  /* width: 28%; */
  width: 20%;
  height: 160px;
  min-width: auto;
  margin: 0 auto 1.5rem;
  border: 2px dashed #FAAF11;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}
.multi-question-drag-answer-image .drag-box:last-child {
  width: 100%;
  flex-direction: row;
  border: 2px solid #FAAF11;
}
.multi-question-drag-answer-image .drag-box .secondary-details {
  width: 120px;
  height: 120px;
  padding: 0 15px;
  position: relative;
}
.multi-question-drag-answer-image .secondary-details img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.multi-question-drag-answer-image form .yellow-button {
  margin: 0 auto;
}
/*------------- 31.12.22 [END] -------------*/


/*------------- 01.01.23 [START] -------------*/
.lessions-display .questions-footer { 
  width: 100%; 
  padding: 15px 15px; 
  border-radius: 0 0 10px 10px; 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: center; 
  background: #E6F4F8;  
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.question-display a.hint-button { 
  font-size: 22px; 
  line-height: 26px; 
  color: #0092B8; 
  display: inline-flex; 
  align-items: center;
}
.question-display .hint-button + .hint-button {
  margin-left: 2rem;
}
.question-display a.hint-button img { 
  margin-right: 10px; 
  width: 25px;
}
.question-display a.hint-button:last-child img { 
  margin-right: 0px; 
}
.question-display .question-btn .yellow-button {
  height: auto;
  padding: 10px 30px;
  font-size: 18px;
  line-height: 22px;
  display: inline-block;
}
.question-image-alpha-numerical-sequence .input-sequence {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.question-image-alpha-numerical-sequence .input-sequence span + span {
  margin-left: 1.5rem;
}
.multi-question-text-drag-answer-image .head-name,
.multi-text-drag-text .head-name {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.multi-question-text-drag-answer-image .head-name p,
.multi-text-drag-text .head-name p {
  width: 28%;
  color: #212529;
  margin: 0 auto 1.5rem;
  text-align: center;
  text-transform: capitalize;  
  font-weight: 600;
  font-size: 22px;
}
.multi-answer-image-multi-checkbox {
  text-align: center;
}
.multi-answer-image-multi-checkbox label span img {
  width: 100px;
}
.multi-question-image-answer-text-true-false ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
}
.multi-question-image-answer-text-true-false ul li {
  width: 28%;
  margin: 0 auto 1.5rem;
}
.multi-question-image-answer-text-true-false ul li img {
  width: 120px;
  height: 120px;
  margin-bottom: 0.5rem;
  object-fit: contain;
}
.multi-question-image-answer-text-true-false ul li h4,
.multi-question-image-answer-text-true-false .answer-info .box-wrap h4 {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 1.5rem;
}
.multi-question-image-answer-text-true-false .answer-info .img-content {
  width: 50%;
  position: relative;
}
.multi-question-image-answer-text-true-false .answer-info .img-content h4 {
  color: #545555;
  margin-bottom: 0.5rem;
  font-size: 18px;
  line-height: 28px;
}
.multi-question-image-answer-text-true-false .answer-info .img-content p {
  font-size: 18px;
  font-weight: 700;
}
.multi-question-image-answer-text-true-false .answer-info .img-content .true {
  color: #0f5132;
}
.multi-question-image-answer-text-true-false .answer-info .img-content .false {
  color: #842029;
}
/*------------- 01.01.23 [END] -------------*/

/*------------- 03.01.23 [START] -------------*/
.question-display .multi-image-options,
.question-display .image-and-options,
.question-display .standard-fill-input,
.question-display .select-checkbox-input,
.question-display .multi-image-dragbox,
.question-display .question-image-multi-image,
.question-display .question-image-alpha-numerical-sequence,
.question-display .multi-answer-image-multi-checkbox,
.question-display .multi-question-drag-answer-image,
.question-display .multi-question-image-answer-text-true-false,
.question-display .question-image-option-abcd,
.question-display .question-answer,
.question-display .question-sub-questions-answers,
.question-display .question-image-position-abcd {
  padding: 50px 15px 80px;
}
.question-display .questions-footer .hint {
  display: inherit;
}
.image-and-options .question-info {
  padding: 0.5rem 1.5rem;
}
/*------------- 03.01.23 [END] -------------*/


/*------------- 04.01.23 [START] -------------*/
.multi-question-image-answer-text-true-false ul li .custom-radio,
.question-image-option-abcd .custom-radio { 
  flex-direction: row;
  justify-content: center;
}
.multi-question-image-answer-text-true-false ul li .custom-radio label,
.question-image-option-abcd .custom-radio label { 
  margin: 0px 15px; 
}
.multi-question-image-answer-text-true-false ul li .custom-radio label .Mui-checked + span,
.question-image-option-abcd .custom-radio label .Mui-checked + span { 
  background: #198754; 
  color: #FFFFFF; 
  border: 1px solid #198754;
}
.multi-question-image-answer-text-true-false ul li .custom-radio label .MuiRadio-colorPrimary,
.question-image-option-abcd .custom-radio label .MuiRadio-colorPrimary { 
  display: none !important; 
}
.multi-question-image-answer-text-true-false ul li .custom-radio span,
.question-image-option-abcd .custom-radio span { 
  padding: 5px 20px; 
  border: 1px solid #FAAF11; 
  border-radius: 10px; 
  font-size: 18px; 
  width: 100%; 
  min-height: 44px;
  word-break: break-word;
  font-weight: 400;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  color: #212529; 
  font-family: 'Quicksand', sans-serif;
}
.multi-question-image-answer-text-true-false .MuiFormControl-rooAt div label span:before,
.question-image-option-abcd .MuiFormControl-rooAt div label span:before { 
  display: none; 
}
.question-sub-questions-answers form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.question-sub-questions-answers form .options-wrap {
  padding: 0 15px;
  margin-bottom: 1rem;
}
.question-image-position-abcd .options-wrap {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
.question-image-position-abcd .options-wrap .input-sequence {
  padding: 0 15px;
  margin-bottom: 1rem;
}
.multi-text-drag-text .drag-box {
  /* width: 20%; */
  min-height: 100px;
  height: auto;
}
.multi-text-drag-text .drag-box h5,
.multi-image-drag-text .drag-box h5,
.multi-text-two-drag .drag-box h5 {
  font-size: 28px;
  margin: 0 1.5rem 0;
  text-transform: capitalize;
}
.multi-image-drag-text .drag-box {
  min-height: 100px;
  height: auto;
}
.multi-text-drag-text .head-name p {
  width: 20%;
}
.multi-image-drag-text .drag-box h5 {
  font-size: 22px;
}
.multi-image-two-drag .drag-box:not(:last-child),
.multi-text-two-drag .drag-box:not(:last-child) {
  width: 45%;
  min-height: 200px;
  height: auto;
  padding: 0px;
  padding-top: 50px;
  flex-direction: row;
  position: relative;
}
.multi-image-two-drag .drag-box .main-head,
.multi-text-two-drag .drag-box .main-head {
  width: 100%;
  text-align: center;
  left: 0;
  top: 0;
  position: absolute;
}
.multi-image-two-drag .drag-box .main-head span,
.multi-text-two-drag .drag-box .main-head span {
  width: 100%;
  padding: 8px;
  border-radius: 5px 5px 0 0;
  text-transform: capitalize;
  display: block;
}
.multi-image-two-drag .drag-box:last-child,
.multi-text-two-drag .drag-box:last-child {
  min-height: 200px;
  height: auto;
  padding: 0px;
  padding-top: 50px;
  align-items: flex-start;
  position: relative;
}
.multi-image-two-drag  .drag-box .secondary-details {
  width: 100px;
  height: 100px;
  margin: 1rem 0;
}
.multi-text-two-drag .drag-box h5 {
  font-size: 22px;
  padding: 1rem 0;
}
/*------------- 04.01.23 [END] -------------*/

/*------------- 06.01.23 [START] -------------*/
.hint .modal-dialog .modal-body img {
  height: 250px;
  margin-right: 2rem;
  object-fit: contain;
}
.hint .modal-dialog .modal-body img:last-child {
  margin-right: 0rem;
}
/*------------- 06.01.23 [END] -------------*/

/*------------- 10.01.23 [START] -------------*/
.sketch-canvas-wrap > div:first-child {
  height: 550px !important;
  overflow: hidden;
}
.sketch-canvas-wrap .sketch-btn {
  text-align: center;
  margin-top: 1rem;
}
/*------------- 10.01.23 [END] -------------*/

/*------------- 27.12.22 [START] -------------*/
.lesson-complete {
  width: 100%;
  padding: 30px;
  margin: 0 10px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  position: relative;
}
.lesson-complete .lesson-img {
  width: 100%;
  height: 300px;
  text-align: center;
  margin-bottom: 3rem;
  pointer-events: none;
}
.lesson-complete .lesson-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.lesson-complete h3 {
  color: #212529;  
  font-size: 30px;
  line-height: 28px;
  font-weight: 700;  
  margin-bottom: 2rem;
}
.lesson-complete h3 span {
  color: #0092B8;
}
.lesson-complete .rating-star {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
/*------------- 27.12.22 [END] -------------*/

/*------------- 23.01.23 [START] -------------*/
.reports-display {
  width: 100%;
  position: relative;
}
.reports-display .question-display {
  border: 1px solid #badbcc;
  border-radius: 10px;
  margin-bottom: 2rem;
  position: relative;
}
.reports-display .question-display .multi-image-options,
.reports-display .question-display .image-and-options,
.reports-display .question-display .standard-fill-input,
.reports-display .question-display .question-image-alpha-numerical-sequence,
.reports-display .question-display .multi-answer-image-multi-checkbox,
.reports-display .question-display .multi-image-dragbox,
.reports-display .question-display .question-image-multi-image,
.reports-display .question-display .multi-question-drag-answer-image,
.reports-display .question-display .multi-question-image-answer-text-true-false,
.reports-display .question-display .question-image-option-abcd,
.reports-display .question-display .question-answer,
.reports-display .question-display .question-sub-questions-answers,
.reports-display .question-display .question-image-position-abcd,
.reports-display .question-display .select-checkbox-input {
  padding: 0px;
}
.reports-display .assignment-question {
  padding: 6px 15px;  
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 18px;
  color: #0f5132;
  font-family: 'Quicksand', sans-serif;
  border-radius: 10px 10px 0px 0px;
  background-color: #d1e7dd;
}
.reports-display .question-display .question-info,
.reports-display .standard-fill-input .input-field,
.reports-display .question-image-alpha-numerical-sequence .input-sequence,
.reports-display .multi-answer-image-multi-checkbox .MuiFormControlLabel-root,
.reports-display .multi-image-dragbox .drag-box > div,
.reports-display .question-image-multi-image .MuiFormControlLabel-root,
.reports-display .multi-question-drag-answer-image .drag-box > div,
.reports-display .multi-question-image-answer-text-true-false ul li .custom-radio,
.reports-display .question-image-option-abcd .custom-radio,
.reports-display .question-answer .input-field,
.reports-display .question-sub-questions-answers .options-wrap,
.reports-display .question-option-abcd .options-wrap,
.reports-display .question-image-position-abcd .options-wrap,
.reports-display .select-checkbox-input .MuiFormControlLabel-root,
.reports-display .multi-question-image-answer-text-true-false ul li,
.reports-display .image-and-options-sound,
.reports-display .question-sound-multi-image,
.reports-display .multi-image-drag-multi-sound {
  pointer-events: none;
}
.question-display .answer-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(35, 33, 91, 0.1);
}
.question-display .answer-info .box {
    width: 50%;
    text-align: center;
    padding-bottom: 1rem;
}
.question-display .answer-info span {
  width: 100%;
  padding: 0.5rem 1rem;
  color: #084298;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  background-color: #e7f1ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-display .answer-info span.correct {
  color: #0f5132;
  /* background-color: #d1e7dd; */
}
.question-display .answer-info span > img {
  width: 30px;
  margin: 0px;
}
.question-display .answer-info .box:first-child {
  border-right: 1px solid rgba(35, 33, 91, 0.1);
}
.question-display .answer-info .box .question-img {
  height: 200px;
}
/*------------- 23.01.23 [END] -------------*/

/*------------- 24.01.23 [START] -------------*/
.question-display .answer-info .img-sec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.question-display .answer-info .img-sec img {
  width: auto;
  height: 90px;
  object-fit: contain;
}
.reports-display .multi-question-image-answer-text-true-false ul {
  margin: 0px;
  border: none;
  border-radius: 0;
}
.question-display .answer-info .img-sec p {
  width: 100%;
  margin-bottom: 0px;
  padding-top: 0.5rem;
}
/*------------- 24.01.23 [END] -------------*/

/*------------- 25.01.23 [START] -------------*/
.reports-display .question-display.wrong-answer {
  border: 1px solid #f5c2c7;
}
.reports-display .wrong-answer .assignment-question {
  color: #842029;
  background-color: #f8d7da;
}
.question-display .multi-image-two-drag .answer-info h5,
.question-display .multi-text-two-drag .answer-info .head-title {
  width: 100%;
  padding: 0.5rem 0;
  display: block;
}
.question-display .multi-text-two-drag .answer-info h5:not(.head-title) {
  margin: 0 0.5rem;
}
.question-display .multi-image-two-drag .answer-info .img-sec,
.question-display .multi-text-two-drag .answer-info .img-sec {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(35, 33, 91, 0.1);
}
.question-display .multi-image-two-drag .answer-info .img-sec + .img-sec,
.question-display .multi-text-two-drag .answer-info .img-sec + .img-sec {
  border: none;
}
.question-display .explanation-info {
  width: 100%;
  clear: both;
}
.question-display .explanation-info p {
  word-break: break-word;
}
.question-display .explanation-info .details span {
  width: 100%;
  padding: 0.5rem 1rem;
  color: #084298;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
  background-color: #e7f1ff;
  display: block;
}
.question-display .explanation-info .details .question-img {
  height: 200px;
}
.question-display .explanation-info .question-btn {
  width: 100%;
  padding: 15px;
  margin-top: 1rem;
  /* border-top: 1px solid rgba(35, 33, 91, 0.1); */
  text-align: right;
  border-radius: 0 0 10px 10px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #E6F4F8;
}
/*------------- 25.01.23 [END] -------------*/

.multi-image-drag-multi-sound .head-name,
.question-sound-multi-image .head-name,
.multi-question-image-sound-true-false .head-name,
.image-and-options-sound .head-name,
.multi-image-dragbox-sound .head-name,
.multi-image-two-drag .head-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.multi-image-drag-multi-sound .head-name a,
.question-sound-multi-image .head-name a,
.multi-question-image-sound-true-false .head-name a,
.image-and-options-sound .head-name a,
.multi-image-two-drag .head-name a {
  width: 28%;
  color: #212529;
  margin: 0 auto 1.5rem;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
}
.question-sound-multi-image .head-name a,
.multi-question-image-sound-true-false .head-name a,
.image-and-options-sound .head-name a {
  width: auto;
}
.multi-image-drag-multi-sound .head-name a img,
.question-sound-multi-image .head-name a img,
.multi-question-image-sound-true-false .head-name a img,
.image-and-options-sound .head-name a img,
.multi-image-dragbox-sound .head-name a img,
.multi-image-two-drag .head-name a img {
  width: 40px;
  height: auto;
  margin: 0px;
  display: inline-block;
}
.question-display .image-and-options-sound .options-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.multi-image-drag-multi-sound .head-name a:hover,
.multi-image-drag-multi-sound .head-name a:focus,
.question-sound-multi-image .head-name a:hover,
.question-sound-multi-image .head-name a:focus,
.multi-question-image-sound-true-false .head-name a:hover,
.multi-question-image-sound-true-false .head-name a:focus,
.image-and-options-sound .head-name a:hover,
.image-and-options-sound .head-name a:focus {
  color: #0092B8;
}


@media (max-width:1280px) { 
  .questions-wrap-box {
    min-height: 550px;
  }
  .lession-header h4 {
    font-size: 22px;
    line-height: 32px;
  }
  .multi-question-image-answer-text-true-false ul li {
    width: 50%;
  }
}

@media(min-width:768px) and (max-width:1023px) {
  /*------------- 03.01.23 [START] -------------*/
  .questions-wrap-box {
    min-height: auto;
    flex-direction: column;
  }
  .questions-wrap-box .question-display {
    height: auto;
  }
  .questions-wrap-box .lessions-display,
  .questions-wrap-box .perform {
    width: calc(100% - 20px);
    margin-bottom: 1.5rem;
  }
  .questions-wrap-box .perform {
    display: flex;
    align-items: center;
    position: relative;
  }
  .questions-wrap-box .lessions-display {
    order: 2;
  }
  .questions-wrap-box .lession-header {
    padding: 10px 20px;
  }
  .questions-wrap-box .lession-header h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .questions-wrap-box .perform .common-in-progress {
    width: 100%;
    min-height: 173px;
    padding: 10px;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
    border-bottom: 0px;
  }
  .questions-wrap-box .perform .common-in-progress:last-child {
    border: none;
  }
  /* .questions-wrap-box .question-display .multi-image-options .question-info {
    width: auto;
    height: 150px;
  } */
  /*------------- 03.01.23 [END] -------------*/

  /*------------- 04.01.23 [START] -------------*/
  .question-display .multi-image-options {
    text-align: center;
  }
  .question-display label {
    margin-bottom: 15px;
  }
  .multi-image-dragbox .drag-box .secondary-details {
    width: 90px;
    height: 90px;
  }
  /*------------- 04.01.23 [END] -------------*/

  /*------------- 24.01.23 [START] -------------*/
  .question-display .answer-info .img-sec img {
    height: 50px;
  }
  /*------------- 24.01.23 [END] -------------*/

  .multi-question-image-answer-text-true-false .answer-info .box-wrap {
    width: 100%;
  }
  .multi-question-image-answer-text-true-false .answer-info .box-wrap:first-child {
    border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    margin-bottom: 0.5rem;
  }
}

@media(max-width:767px) {
  /*------------- 28.12.22 [START] -------------*/
  .questions-wrap-box {
    min-height: auto;
    flex-direction: column;
  }
  .questions-wrap-box .lessions-display,
  .questions-wrap-box .perform {
    width: calc(100% - 20px);
    margin-bottom: 1.5rem;
  }
  .questions-wrap-box .perform {
    display: flex;
    align-items: center;
    position: relative;
  }
  .questions-wrap-box .lessions-display {
    order: 2;
  }
  .questions-wrap-box .lession-header {
    padding: 10px 20px;
  }
  .questions-wrap-box .lession-header h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .questions-wrap-box .lessions-display a.hint-button {
    font-size: 18px;
    line-height: 28px;
  }
  .questions-wrap-box .lessions-display a.hint-button img {
    width: 20px;
    margin-right: 10px;
  }
  .questions-wrap-box .lessions-display .hint .yellow-button {
    padding: 4px 25px;
    font-size: 14px;
  }
  .questions-wrap-box .perform .common-in-progress {
    width: 100%;
    min-height: 112px;
    padding: 10px;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
    border-bottom: 0px;
  }
  .questions-wrap-box .perform .common-in-progress:last-child {
    border: none;
  }
  .questions-wrap-box .perform .common-in-progress span {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .questions-wrap-box .perform .common-in-progress video {
    width: 80%;
  }
  /*------------- 28.12.22 [END] -------------*/

  /*------------- 27.12.22 [START] -------------*/
  .lesson-complete .lesson-img {
    height: 150px;
  } 
  .lesson-complete h3 {
    font-size: 22px;
    margin-bottom: 0.5rem;
  }
  .rating-star span {
    font-size: 30px !important;
  }
  /*------------- 27.12.22 [END] -------------*/

  /*------------- 03.01.23 [START] -------------*/
  .questions-wrap-box .question-display {
    height: auto;
  }
  .questions-wrap-box .common-in-progress .CircularProgressbar {
    width: 40%;
  }
  .questions-wrap-box .CountdownCircleTimer {
    font-size: 15px;
  }
  .questions-wrap-box .common-in-progress .CountdownCircleTimer > div,
  .questions-wrap-box .common-in-progress .CountdownCircleTimer svg {
    width: 65% !important;
    height: 65% !important;
  }
  .question-display .multi-image-options {
    text-align: center;
  }
  /* .questions-wrap-box .question-display .multi-image-options .question-info {
    width: auto;
    height: 150px;
  } */
  /*------------- 03.01.23 [END] -------------*/

  /*------------- 04.01.23 [START] -------------*/
  .question-display .img-box {
    height: 200px;
  }
  .question-display .standard-fill-input .input-field,
  .question-display .question-answer .input-field {
    width: 50%;
  }
  .question-display .MuiCheckbox-colorPrimary svg {
    width: 1.5em;
    height: 1.5em;
  }
  .question-display label h4 {
    font-size: 22px;
  }
  /* .question-display label {
    margin-bottom: 15px;
  } */
  .multi-image-dragbox .drag-box .secondary-details {
    width: 90px;
    height: 90px;
  }
  .question-image-multi-image label .multi-img {
    height: 50px;
  }
  .multi-answer-image-multi-checkbox label span img {
    width: 50px;
  }
  .multi-question-text-drag-answer-image .head-name p,
  .multi-text-drag-text .head-name p,
  .multi-image-drag-multi-sound .head-name a {
    margin: 0 auto;
  }
  .multi-text-drag-text .head-name p {
    font-size: 14px;
  }
  .multi-text-drag-text .drag-box h5 {
    font-size: 20px;
  }
  .multi-image-drag-text .drag-box h5,
  .multi-text-two-drag .drag-box h5 {
    font-size: 15px;
  }
  .multi-image-two-drag .drag-box .main-head span {
    padding: 5px;
    font-size: 14px;
  }
  /*------------- 04.01.23 [END] -------------*/

  /*------------- 23.01.23 [START] -------------*/
  .question-display .answer-info .box {
    width: 100%;
  }
  .question-display .answer-info .box:first-child {
    border-right: 0px;
    border-bottom: 1px solid rgba(35, 33, 91, 0.1);
  }
  /*------------- 23.01.23 [END] -------------*/

  /*------------- 24.01.23 [START] -------------*/
  .question-display .answer-info .img-sec img {
    height: 50px;
  }
  /*------------- 24.01.23 [END] -------------*/

  /*------------- 25.01.23 [START] -------------*/
  .question-display .answer-info span > img {
    width: 25px;
  }
  /*------------- 25.01.23 [END] -------------*/

  .multi-question-image-answer-text-true-false .answer-info .box-wrap {
    width: 100%;
  }
  .multi-question-image-answer-text-true-false .answer-info .box-wrap:first-child {
    border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    margin-bottom: 0.5rem;
  }
}


@media(max-width:480px) {
  /*------------- 03.01.23 [START] -------------*/
  .questions-wrap-box .common-in-progress .CircularProgressbar {
    width: 60%;
  }
  .questions-wrap-box .CountdownCircleTimer {
    font-size: 12px;
  }
  .questions-wrap-box .common-in-progress .CountdownCircleTimer > div,
  .questions-wrap-box .common-in-progress .CountdownCircleTimer svg {
    width: 80% !important;
    height: 80% !important;
  }
  /*------------- 03.01.23 [END] -------------*/

  /*------------- 04.01.23 [START] -------------*/
  .question-display .standard-fill-input .input-field,
  .question-display .question-answer .input-field {
    width: 70%;
  }
  .question-display .multi-image-dragbox .drag-box {
    height: 130px;
    min-height: auto;
  }
  .multi-image-dragbox .drag-box .secondary-details,
  .multi-question-drag-answer-image .drag-box .secondary-details {
    width: 60px;
    height: 60px;
  }
  .multi-question-drag-answer-image .img-sec img {
    width: 80px;
  }
  .multi-question-drag-answer-image .drag-box {
    height: 100px;
  }
  .multi-question-text-drag-answer-image .head-name p,
  .multi-text-drag-text .head-name p,
  .multi-image-drag-multi-sound .head-name a {
    font-size: 11px;
  }
  .multi-question-image-answer-text-true-false ul li {
    width: 100%;
  }
  .multi-text-drag-text .drag-box,
  .multi-image-drag-text .drag-box:not(:last-child) {
    height: 60px;
    min-height: auto;
  }
  /*------------- 04.01.23 [END] -------------*/

  .sketch-canvas-wrap > div:first-child {
    height: 350px !important;
  }
}