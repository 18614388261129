section.testimonial {
    padding: 70px 0px 55px 0px;
}
.testimonial-slider-repeat {
    padding: 10px ;
 }
 .testimonial-wrap{ 
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
    border-radius: 60px 60px 0px 0px;
    padding: 27px 27px 35px 19px; 
    position: relative; 
}
.testimonial-slider-repeat p {
    margin: 0px;
    font-weight: 400;
    font-family: 'Catamaran';
    line-height: 33px;
    color: #23454E; 
    padding-left: 8px;
    word-break: break-word;
}
.testimonial-slider{ margin: 0px -10px;}
.avtar-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 27px;
}
.avtar-details img {
    width: 87px;
    height: 87px;
    border-radius: 108px 114px 65px 108px;
    margin-right: 10px;
    overflow: hidden; 
    object-fit: cover;
}
.avtar-details .avtar-name {
    flex: 1 1;
    padding-right: 2rem;
    word-break: break-word;
}
.avtar-name h5 {
    text-align: left;
    line-height: 28px;
}
.testimonial-wrap .avtar-name span {
    text-align: left;
    line-height: 24px;
    display: block;
}
.icour-math {
    padding: 70px 15px 60px 15px;
    text-align: center;
    position: relative;
    background-color: #fff;
    margin-bottom: 60px;
}
.icour-math strong  a{
    color:  #0092B8;
}
.icour-math strong {
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #23215B;
    font-weight: 600;
    display: block;
    max-width: 1100px;
    margin: 0 auto 20px;
}
.icour-math:before {
    content: '';
    position: absolute;
    bottom: -66px;
    left: 0px;
    width: 100%;
    height: 67px;
    background: url(../../../assets/images/curve.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
}
.testimonials-main {
    background: #F7F7F7;
}
.testimonials-wraper .testimonial-wrap {
    border-radius: 20px;
    cursor: pointer;
}
.testimonials-repeat .testimonial-slider-repeat {
    width: 40%;
}
.testimonials-repeat {
    display: flex;
    flex-wrap: wrap;
}
.testimonials-repeat .testimonial-slider-repeat:nth-child(4n+1), .testimonials-repeat .testimonial-slider-repeat:nth-child(4n+4) {
    width:  60%
}
.testimonials-repeat .testimonial-wrap{ height: 100%; }
.testimonials-wraper {
    padding: 70px 0px 40px;
}
.testimonils-pop .modal-dialog {
    max-width: 876px;
}
.testimonils-pop .modal-dialog .modal-title.h4 {
    display: none;
}
.testimonils-pop .modal-dialog  .modal-body {
    padding: 30px;
}
.testimonils-pop .modal-dialog img {
    margin: 0 auto;
    display: block;
    border-radius: 100%;
    width: 86px;
    height: 86px;
    border-radius: 10px;
}
.testimonils-pop .avtar-name {
    text-align: center;
    margin-bottom: 10px;
}
.testimonils-pop .avtar-name h5 {
    text-align: center;
    margin-top: 10px;
    line-height: 27px;
}
.testimonils-pop .avtar-name p {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #23454E;
}
.testimonils-pop .modal-dialog .modal-body p {
    margin-bottom: 10px;
    color: #23454E;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #23454E;
    word-break: break-word;
}
.home-main .common-sections-main .common-section-image {
    -webkit-mask-image: url("../../../assets/images/inner-mark.png");
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
}
.common-section-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 1440px){
    .icour-math strong {
        font-size: 22px;
        line-height: 32px;
    }
    .avtar-details img {
        width: 60px;
        height: 60px;
    }
    .avtar-details:before {
        width: 40px;
        height: 40px;
    }    
}

@media(max-width : 1200px){
    .icour-math strong {
        font-size: 24px;
        line-height: 32px;
    }
    .testimonials-wraper {
        padding: 40px 0px 30px;
    }
    .icour-math{ margin-bottom: 20px;}
}

@media(max-width:  992px){
    .testimonials-repeat .testimonial-slider-repeat {
        width: 50%;
    }
    .testimonials-repeat .testimonial-slider-repeat:nth-child(4n+1), .testimonials-repeat .testimonial-slider-repeat:nth-child(4n+4) {
        width: 50%
    }
}

@media(max-width :767px){ 
    .icour-math strong {
        font-size: 18px;
        line-height: 30px;
    }
    .testimonial-slider-repeat p {
        padding: 0px;
        line-height: 26px;
    }
    .icour-math {
        padding: 40px 15px 40px 15px;
    }
    .testimonials-wraper {
        padding: 40px 0px 30px;
        margin-bottom: 3rem;
    }
    .testimonials-repeat .testimonial-slider-repeat {
        width: 100%;
    }
    .testimonials-repeat .testimonial-slider-repeat:nth-child(4n+1), .testimonials-repeat .testimonial-slider-repeat:nth-child(4n+4) {
        width: 100%;
    }
    .avtar-details img {
        width: 60px;
        height: 60px;
    }
    .avtar-name span {
        font-size: 15px;
        line-height: 20px;
        margin-top: 5px;
        display: block; 
        text-align: left;
    }
    .avtar-details:before {
        width: 30px;
        top: 15px;
    }
    .avtar-details{ margin-bottom: 15px; }
    /* .testimonial-slider-repeat { padding-left: 0px; padding-right: 0px; } */
}